import React from 'react';
import {
    Badge,
    Button,
    ColumnLayout,
    Table,
} from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';

import Section, {
    ColumnContentData,
    SectionProps,
} from '../../../../../common/components/Section/Section';
import Can from '../../../../../common/components/Can';
import { getUserRoles } from '../../../../../common/utils/auth';
import { Actions } from '../../../../../common/constants/auth';

import {
    EMPTY_STRING,
    SectionContentType,
} from '../../../../../common/constants/grimsby';
import {
    formatYesNoBoolean,
    getInstructorProviderValues,
} from '../../Common/Common';
import formatDate from '../../../../../common/utils/formatDate';
import { ProviderItem } from '../DetailsTab/DetailsTab';
import { InstructorProfileData } from '../../../../interfaces/instructorProfile';
import TableHeader from '../../../../../common/components/TableHeader/TableHeader';
import './ExternalInstructorDetails.scss';
import { formatString } from '../../../../../common/utils/formatString';

export const SPONSORING_COMPANY_BADGE_TEXT = 'Sponsoring company';
const providersColumnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'ProviderName',
        header: 'Provider name',
        cell: (item: ProviderItem) => (
            <span>
                {item.providerName}
                {item.isSponsoringCompany && (
                    <span className="awsui-util-ml-s">
                        <Badge color="blue">
                            {SPONSORING_COMPANY_BADGE_TEXT}
                        </Badge>
                    </span>
                )}
            </span>
        ),
    },
    {
        id: 'AWSClassroomsEmail',
        header: 'AWS Classrooms email',
        cell: (item: ProviderItem) => formatString(item.awsClassroom),
    },
    {
        id: 'CompanyContactEmail',
        header: 'Company contact email',
        cell: (item: ProviderItem) => formatString(item.companyContact),
    },
];

const ExternalInstructorDetails = ({
    profile,
    isEditButtonVisible,
    columns = 4,
}: {
    profile: InstructorProfileData;
    isEditButtonVisible: boolean;
    columns?: number;
}) => {
    const history = useHistory();
    const externalProps: SectionProps = {
        testId: 'ExternalInstructorDetailsSection',
        className: 'awsui-util-mb-n',
        header: {
            label: 'External instructor details',
            buttons: isEditButtonVisible ? (
                <Can
                    roles={getUserRoles()}
                    perform={Actions.INSTRUCTOR_MODIFY}
                    yes={() => (
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                data-testid="ExternalInstructorDetailsSectionEditButton"
                                onClick={() => {
                                    history.push({
                                        pathname: `/instructors/${profile.pk}/edit-details`,
                                    });
                                }}
                            >
                                Edit
                            </Button>
                        </div>
                    )}
                    no={() => null}
                />
            ) : null,
        },
    };

    const authorizationsProps: SectionProps = {
        testId: 'AuthorizationsSection',
        className: 'awsui-util-mb-n grimsby-util-border-t-n',
        header: {
            label: 'Authorizations',
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: columns as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'Nondisclosure agreement (NDA) signed',
                        value: formatYesNoBoolean(profile.signed_nda),
                    },
                ],
                [
                    {
                        key: 'Authorized instructor agreement status',
                        value: formatString(
                            profile.authorized_instructor_agreement_status,
                        ),
                    },
                ],
                [
                    {
                        key: 'AWS authorized candidate fundamentals',
                        value: formatYesNoBoolean(
                            profile.aws_authorized_candidate_fundamentals,
                        ),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const freelanceDetailsProps: SectionProps = {
        testId: 'FreelanceDetailsSection',
        header: {
            label: 'Freelance details',
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: columns as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'Is in AWS freelancer program?',
                        value: formatYesNoBoolean(profile.is_freelancer),
                    },
                    {
                        key: 'Freelancer contact email',
                        value: formatString(profile.freelance_poc_email),
                    },
                ],
                [
                    {
                        key: 'Freelancer status',
                        value: formatString(profile.freelancer_status),
                    },
                ],
                [
                    {
                        key: 'Freelance agency/ATP',
                        value: formatString(profile.freelance_agency),
                    },
                ],
                [
                    {
                        key: 'Freelance onboarding date',
                        value: profile.freelance_onboarding_date
                            ? formatString(
                                  formatDate(
                                      profile.freelance_onboarding_date * 1000,
                                  ),
                              )
                            : EMPTY_STRING,
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const generateProviderItems = (): Array<ProviderItem> => {
        const { sponsoringCompany, providers } = getInstructorProviderValues(
            profile.providers || [],
        );
        const providerItemsList = providers.length
            ? providers.map((provider) => ({
                  providerName: provider.provider_name,
                  awsClassroom: provider.aws_classrooms_email,
                  companyContact: null,
                  isSponsoringCompany: false,
              }))
            : [];
        const sponsoringCompanyItemList = sponsoringCompany
            ? [
                  {
                      providerName: sponsoringCompany.provider_name,
                      awsClassroom: sponsoringCompany.aws_classrooms_email,
                      companyContact: profile.external_poc,
                      isSponsoringCompany: true,
                  },
              ]
            : [];
        return [
            ...sponsoringCompanyItemList,
            ...providerItemsList,
        ] as Array<any>;
    };

    const emptyProviderTableComponent = (
        <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No providers</b>
            </div>
            <p className="awsui-util-mb-s">Providers will display here.</p>
        </div>
    );

    return (
        <>
            <Section {...externalProps} />
            <section className="grimsby-util-border-t-n awsui-util-container awsui-util-mb-n awsui-util-no-gutters">
                <Table
                    data-testid="ProvidersTable"
                    columnDefinitions={providersColumnDefinitions}
                    items={generateProviderItems()}
                    header={
                        <TableHeader
                            title="Providers"
                            actions={<React.Fragment />}
                        />
                    }
                    empty={emptyProviderTableComponent}
                    variant="borderless"
                />
            </section>
            <Section {...authorizationsProps} />
            <Section {...freelanceDetailsProps} />
        </>
    );
};

export default ExternalInstructorDetails;
