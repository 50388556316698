import { HelpPanelConfig } from '../components/HelpPanel/helpPanelTypes';
import { HELP_PANELS_MAP } from '../components/HelpPanel/helpPanelMap';

interface RouteMatchParams {
    pathname: string;
    route: string;
}

export const isExactMatch = ({ pathname, route }: RouteMatchParams): boolean =>
    pathname === route;

export const matchesWithTrailingSlash = ({
    pathname,
    route,
}: RouteMatchParams): boolean => pathname.startsWith(route + '/');

export const matchesWithoutTrailingSlash = ({
    pathname,
    route,
}: RouteMatchParams): boolean => pathname === route.slice(0, -1);

export const findMatchingPanel = (
    pathname: string,
): HelpPanelConfig | undefined => {
    const sortedPanels = Object.values(HELP_PANELS_MAP).sort(
        (a, b) => b.route.length - a.route.length,
    );

    return sortedPanels.find(
        (panel) =>
            isExactMatch({ pathname, route: panel.route }) ||
            matchesWithTrailingSlash({ pathname, route: panel.route }) ||
            matchesWithoutTrailingSlash({ pathname, route: panel.route }),
    );
};
