import { useEffect, useState } from 'react';
import { LocationItemData } from '../../../../../common/interfaces/businessDataItem/locationItem';
import businessDataApi from '../../../../../common/api/businessDataApi';

export const useDeliveryDetailsFormOptions = () => {
    type FormOptions = {
        activityTypes: string[];
        activityModalities: string[];
        activityAudiences: string[];
        locations: LocationItemData[];
    };

    const [formOptions, setFormOptions] = useState<FormOptions>({
        activityTypes: [],
        activityModalities: [],
        activityAudiences: [],
        locations: [],
    });

    useEffect(() => {
        const getActivityTypes = () =>
            businessDataApi
                .getActivityTypes({ active: true })
                .then(({ result }) =>
                    result.ACTIVITY_TYPE.map(
                        ({ activity_type }) => activity_type,
                    ),
                );
        const getActivityModalities = () =>
            businessDataApi
                .getActivityModalities({ active: true })
                .then(({ result }) =>
                    result.ACTIVITY_MODALITY.map(
                        ({ activity_modality }) => activity_modality,
                    ),
                );
        const getActivityAudiences = () =>
            businessDataApi
                .getActivityAudiences({ active: true })
                .then(({ result }) =>
                    result.ACTIVITY_AUDIENCE.map(
                        ({ activity_audience }) => activity_audience,
                    ),
                );
        const getLocations = () =>
            businessDataApi
                .getLocations({ active: true })
                .then(({ result }) => result.CITY);

        Promise.all([
            getActivityTypes(),
            getActivityModalities(),
            getActivityAudiences(),
            getLocations(),
        ]).then(
            ([
                activityTypes,
                activityModalities,
                activityAudiences,
                locations,
            ]) => {
                setFormOptions({
                    activityTypes,
                    activityModalities,
                    activityAudiences,
                    locations,
                });
            },
        );
    }, []);

    return formOptions;
};
