import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Input,
    Spinner,
    Alert,
    Button,
    Checkbox,
    Select,
    Multiselect,
    Autosuggest,
    FormSection,
    FormField,
    Form,
    ColumnLayout,
    Modal,
    DatePicker,
} from '@amzn/awsui-components-react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';
import {
    getSelectedInstructor,
    selectError as selectSelectedInstructorError,
    selectIsLoading as selectSelectedInstructorLoading,
    selectIsLoaded as selectSelectedInstructorLoaded,
    selectSelectedInstructor,
    updateSelectedInstructor,
    setRequireUpdate,
} from '../../../store/slices/selectedInstructorSlice';

import {
    initializeDeliveryCountriesListQueryParams,
    resetPartialDeliveryCountriesSlice,
    resetDeliveryCountriesSlice,
    getDeliveryCountriesList,
    selectError as selectDeliveryCountriesError,
    selectAllActiveDeliveryCountries,
    selectIsLoaded as selectDeliveryCountriesIsLoaded,
    selectIsLoading as selectDeliveryCountriesIsLoading,
} from '../../../../common/store/slices/deliveryCountriesSlice';
import { DeliveryCountryItemData } from '../../../../common/interfaces/businessDataItem/deliveryCountryItem';
import {
    resetDeliveryLanguagesSlice,
    getDeliveryLanguagesList,
    selectError as selectDeliveryLanguagesError,
    selectAllActiveDeliveryLanguages,
    selectIsLoaded as selectDeliveryLanguagesIsLoaded,
    selectIsLoading as selectDeliveryLanguagesIsLoading,
} from '../../../../common/store/slices/deliveryLanguagesSlice';
import { DeliveryLanguageItemData } from '../../../../common/interfaces/businessDataItem/deliveryLanguageItem';
import {
    initializeCoursesListQueryParams,
    resetPartialCoursesSlice,
    resetCoursesSlice,
    getCoursesList,
    getAllCoursesList,
    selectError as selectCoursesError,
    selectAllActiveCourses,
    selectIsLoaded as selectCoursesIsLoaded,
    selectIsLoading as selectCoursesIsLoading,
} from '../../../../common/store/slices/coursesSlice';
import { CourseItemData } from '../../../../common/interfaces/businessDataItem/courseItem';
import {
    resetInstructorCourseStatusesSlice,
    getInstructorCourseStatusesList,
    selectError as selectInstructorCourseStatusesError,
    selectAllActiveInstructorCourseStatuses,
    selectIsLoaded as selectInstructorCourseStatusesIsLoaded,
    selectIsLoading as selectInstructorCourseStatusesIsLoading,
} from '../../../../common/store/slices/instructorCourseStatusesSlice';
import { InstructorCourseStatusItemData } from '../../../../common/interfaces/businessDataItem/instructorCourseStatusItem';
import {
    resetDomainSkillsSlice,
    getDomainSkillsList,
    selectError as selectDomainSkillsError,
    selectAllActiveDomainSkills,
    selectIsLoaded as selectDomainSkillsIsLoaded,
    selectIsLoading as selectDomainSkillsIsLoading,
} from '../../../../common/store/slices/domainSkillsSlice';
import {
    resetProgramTypesSlice,
    getProgramTypesList,
    selectIsLoaded as selectProgramTypesIsLoaded,
    selectIsLoading as selectProgramTypesIsLoading,
} from '../../../../common/store/slices/programTypesSlice';
import {
    selectFeatures,
    selectIsLoaded as selectIsFeaturesLoaded,
} from '../../../../common/store/slices/featureSlice';
import { checkFeature } from '../../../../common/utils/featureFlag';
import { DomainSkillItemData } from '../../../../common/interfaces/businessDataItem/domainSkillItem';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import handleInstructorDetailNotification from '../../../../common/utils/handleInstructorDetailNotification';
import {
    CertmetricsCandidateIdItem,
    InstructorProfileData,
} from '../../../interfaces/instructorProfile';

import {
    CourseAttributeEditorItem,
    CourseAttributeEditorItemError,
    BusinessDataReactState,
    ErrorMessage,
    preprocessForBusinessData,
} from './EditSkillsAndCourses.common';
import {
    formatCertificationStatus,
    RAMPING_STATUS_VALUE,
} from '../Common/Common';
import {
    generateSelectedOptions,
    handleStateUpdateHelper,
} from '../FormSections/FormSections.common';
import CancelModal, {
    CancelModalProps,
} from '../../../../common/components/CancelModal/CancelModal';
import MultiRowAttributeEditor from '../../../../common/components/MultiRowAttributeEditor/MultiRowAttributeEditor';
import { RowDefinition } from '../../../../common/interfaces/rowDefinition';
import { getISOString } from '../FormSections/BasicInfoFormSection';

const candidateIdItemLookup: { [key: string]: Function } = {
    fetched: (item: CertmetricsCandidateIdItem, index: number) => {
        return (
            <div key={index} className="awsui-util-pb-s">
                <div className="awsui-util-label">Validated certification</div>
                {formatCertificationStatus('Fetched')}
                {item.id}: {item.first_name} {item.last_name} | {item.email}
            </div>
        );
    },

    fetching: (item: CertmetricsCandidateIdItem, index: number) => {
        return (
            <div key={index} className="awsui-util-pb-s">
                <div className="awsui-util-label">Pending certification</div>
                {formatCertificationStatus('Fetching')} Fetching data for
                Certmetrics Candidate ID {item.id}
            </div>
        );
    },
};

export enum CourseRowId {
    RowOne = 1,
    RowTwo,
    RowThree,
}

const getSeconds = (date: string) =>
    Math.round(new Date(date).getTime() / 1000);

const EditSkillsAndCourses = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { addNotification } = useNotifications();
    const match = useRouteMatch<{ id: string }>();
    const instructorError = useSelector(selectSelectedInstructorError);
    const instructorLoading = useSelector(selectSelectedInstructorLoading);
    const instructorLoaded = useSelector(selectSelectedInstructorLoaded);
    const instructor = useSelector(selectSelectedInstructor)?.instructor;
    const featureFlags = useSelector(selectFeatures);
    const featuresLoaded = useSelector(selectIsFeaturesLoaded);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

    // states for checkboxes
    const [checkboxStatesInitialized, setCheckboxStatesInitialized] =
        useState(true);
    const [vILT, setVILT] = useState(false);
    const [prCertified, setPrCertified] = useState(false);
    const [champion, setChampion] = useState(false);
    const [mentor, setMentor] = useState(false);

    const tc3CatalogCoursesFF = useMemo(() => {
        return checkFeature(
            '',
            { featureName: 'tc3-catalog-courses' },
            featureFlags?.features,
        );
    }, [featuresLoaded, featureFlags]);

    //values for speaker certification
    const NOT_SPEAKER_CERTIFIED = 'Not Certified';
    const speakerCertifiedOptions = [
        {
            id: NOT_SPEAKER_CERTIFIED,
            label: NOT_SPEAKER_CERTIFIED,
        },
        {
            id: 'Associate Speaker Certification',
            label: 'Associate Speaker Certification',
        },
        {
            id: 'Senior Speaker Certification',
            label: 'Senior Speaker Certification',
        },
        {
            id: 'Principal Speaker Certification',
            label: 'Principal Speaker Certification',
        },
    ];
    const [speakerCertifiedSelectedOption, setSpeakerCertifiedSelectedOption] =
        useState(null);

    // states for delivery country
    const countriesLoaded = useSelector(selectDeliveryCountriesIsLoaded);
    const countriesLoading = useSelector(selectDeliveryCountriesIsLoading);
    const countriesList = useSelector(selectAllActiveDeliveryCountries);
    const countriesError = useSelector(selectDeliveryCountriesError);
    const [countriesState, setDeliveryCountriesState] = useState({
        status: 'loading',
        selectLookup: {},
        selectOptions: [],
        selectedOptions: [],
        selectedOptionsInitialized: false,
    } as BusinessDataReactState);

    // states for delivery language
    const languagesLoaded = useSelector(selectDeliveryLanguagesIsLoaded);
    const languagesLoading = useSelector(selectDeliveryLanguagesIsLoading);
    const languagesList = useSelector(selectAllActiveDeliveryLanguages);
    const languagesError = useSelector(selectDeliveryLanguagesError);
    const [languagesState, setDeliveryLanguagesState] = useState({
        status: 'loading',
        selectLookup: {},
        selectOptions: [],
        selectedOptions: [],
        selectedOptionsInitialized: false,
    } as BusinessDataReactState);

    // states for domain skills
    const domainSkillsLoaded = useSelector(selectDomainSkillsIsLoaded);
    const domainSkillsLoading = useSelector(selectDomainSkillsIsLoading);
    const domainSkillsList = useSelector(selectAllActiveDomainSkills);
    const domainSkillsError = useSelector(selectDomainSkillsError);
    const [domainSkillsState, setDomainSkillsState] = useState({
        status: 'loading',
        selectLookup: {},
        selectOptions: [],
        selectedOptions: [],
        selectedOptionsInitialized: false,
    } as BusinessDataReactState);

    // states for instructor teaching course statuses
    const courseStatusesLoaded = useSelector(
        selectInstructorCourseStatusesIsLoaded,
    );
    const courseStatusesLoading = useSelector(
        selectInstructorCourseStatusesIsLoading,
    );
    const courseStatusesList = useSelector(
        selectAllActiveInstructorCourseStatuses,
    );
    const courseStatusesError = useSelector(
        selectInstructorCourseStatusesError,
    );
    const [courseStatusesState, setInstructorCourseStatusesState] = useState({
        status: 'loading',
        selectLookup: {},
        selectOptions: [],
    } as BusinessDataReactState);

    // states for program types
    const programTypesLoaded = useSelector(selectProgramTypesIsLoaded);
    const programTypesLoading = useSelector(selectProgramTypesIsLoading);

    // states for courses
    const coursesLoaded = useSelector(selectCoursesIsLoaded);
    const coursesLoading = useSelector(selectCoursesIsLoading);
    const coursesList = useSelector(selectAllActiveCourses);
    const coursesError = useSelector(selectCoursesError);
    const [coursesState, setCoursesState] = useState({
        status: 'loading',
        autosuggestLookup: {},
        autosuggestOptions: [],
        nameSet: new Set(),
    } as BusinessDataReactState);

    const [isInvalid, setIsInvalid] = useState(false);

    // state for added course items
    const [courseAttributeEditorItems, setCourseAttributeEditorItems] =
        useState([] as Array<CourseAttributeEditorItem>);
    const [
        courseAttributeEditorItemErrors,
        setCourseAttributeEditorItemErrors,
    ] = useState([] as Array<CourseAttributeEditorItemError>);
    const [
        courseAttributeEditorItemsInitialized,
        setCourseAttributeEditorItemsInitialized,
    ] = useState(false);

    // state for candidate ids
    const [candidateItems, setCandidateItems] = useState(
        [] as Array<CertmetricsCandidateIdItem>,
    );
    const [candidateItemID, setCandidateItemID] = useState('');
    const [
        candidateItemsRemoveModalVisible,
        setCandidateItemsRemoveModalVisible,
    ] = useState(false);
    const [candidateItemsInitialized, setCandidateItemsInitialized] =
        useState(false);

    // might need to re-design this method to comply with a generic model/functionality
    const validateFormData = useCallback(
        (
            courseAttributeEditorItems: Array<CourseAttributeEditorItem>,
            setCourseAttributeEditorItemErrors: React.Dispatch<
                React.SetStateAction<Array<CourseAttributeEditorItemError>>
            >,
            coursesState: BusinessDataReactState,
            courseStatusesState: BusinessDataReactState,
        ) => {
            let isInvalid = false;

            const newErrors = courseAttributeEditorItems.map((item) => {
                let error = {
                    name: null,
                    status: null,
                } as CourseAttributeEditorItemError;

                const emptyName = !item.nameValue;
                const emptyStatus = !item.statusOption;

                // if both name and status are empty
                if (emptyName && emptyStatus) {
                    error.name = ErrorMessage.EmptyName;
                    error.status = ErrorMessage.EmptyStatus;
                }
                // if only name is empty
                else if (emptyName) {
                    error.name = ErrorMessage.EmptyName;
                }
                // if only status is empty
                else if (emptyStatus) {
                    error.status = ErrorMessage.EmptyStatus;

                    // if name is not in DB
                    if (
                        !(coursesState.nameSet as Set<string>).has(
                            item.nameValue as string,
                        )
                    ) {
                        error.name = ErrorMessage.InvalidName;
                    }
                }
                // if both name and status have value
                else {
                    // if name is not in DB
                    if (
                        !(coursesState.nameSet as Set<string>).has(
                            item.nameValue as string,
                        )
                    ) {
                        error.name = ErrorMessage.InvalidName;
                    }
                }

                // overwrite error message if the business data is not loaded
                if (coursesState.status !== 'finished') {
                    error.name = ErrorMessage.NameDataNotReady;
                }
                if (courseStatusesState.status !== 'finished') {
                    error.status = ErrorMessage.StatusDataNotReady;
                }

                if (error.name || error.status) {
                    isInvalid = true;
                }

                return error;
            });

            setCourseAttributeEditorItemErrors(newErrors);
            setIsInvalid(isInvalid);

            return !isInvalid;
        },
        [],
    );

    // lifecycle method to initialize and reset business data slices
    useEffect(() => {
        // initialize query params for business data slices
        // this code block should only run once
        [
            initializeCoursesListQueryParams,
            initializeDeliveryCountriesListQueryParams,
        ].forEach((initializeQueryParams) =>
            dispatch(
                initializeQueryParams({
                    active: null,
                    size: 0,
                }),
            ),
        );

        return () => {
            // reset business data slices
            // this code block should only run once
            [
                resetDeliveryCountriesSlice,
                resetDeliveryLanguagesSlice,
                resetCoursesSlice,
                resetInstructorCourseStatusesSlice,
                resetDomainSkillsSlice,
                resetProgramTypesSlice,
            ].forEach((resetFunction) => dispatch(resetFunction()));
        };
    }, [dispatch]);

    // lifecycle method to fetch (and refetch) business data
    useEffect(() => {
        (
            [
                [
                    !countriesLoaded && !countriesLoading,
                    getDeliveryCountriesList,
                ],
                [
                    !languagesLoaded && !languagesLoading,
                    getDeliveryLanguagesList,
                ],
                [
                    !domainSkillsLoaded && !domainSkillsLoading,
                    getDomainSkillsList,
                ],
                tc3CatalogCoursesFF
                    ? [
                          !coursesLoaded &&
                              !coursesLoading &&
                              programTypesLoaded &&
                              instructorLoaded,
                          getAllCoursesList,
                      ]
                    : [!coursesLoaded && !coursesLoading, getCoursesList],
                [
                    !courseStatusesLoaded && !courseStatusesLoading,
                    getInstructorCourseStatusesList,
                ],
                tc3CatalogCoursesFF
                    ? [
                          !programTypesLoaded && !programTypesLoading,
                          getProgramTypesList,
                      ]
                    : [],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    // lifecycle method to initialize and reset selected instructor slice, and validate form data
    useEffect(() => {
        if (
            (!instructorLoaded ||
                (instructor && instructor.pk !== match.params.id)) &&
            !instructorLoading
        ) {
            dispatch(getSelectedInstructor(match.params.id));
        }
        if (isInvalid) {
            validateFormData(
                courseAttributeEditorItems,
                setCourseAttributeEditorItemErrors,
                coursesState,
                courseStatusesState,
            );
        }
    }, [
        dispatch,
        match.params.id,
        instructor,
        instructorLoading,
        instructorLoaded,
        isInvalid,
        courseAttributeEditorItems,
        coursesState,
        courseStatusesState,
        validateFormData,
    ]);

    const handleDeliveryCountriesStateUpdate =
        handleStateUpdateHelper<BusinessDataReactState>(
            setDeliveryCountriesState,
        );
    const handleDeliveryLanguagesStateUpdate =
        handleStateUpdateHelper<BusinessDataReactState>(
            setDeliveryLanguagesState,
        );
    const handleDomainSkillsStateUpdate =
        handleStateUpdateHelper<BusinessDataReactState>(setDomainSkillsState);
    const handleInstructorCourseStatusesStateUpdate =
        handleStateUpdateHelper<BusinessDataReactState>(
            setInstructorCourseStatusesState,
        );
    const handleCoursesStateUpdate =
        handleStateUpdateHelper<BusinessDataReactState>(setCoursesState);

    // IIFE for preprocessing all of business data
    (() => {
        preprocessForBusinessData<DeliveryCountryItemData>(
            instructor?.delivery_countries,
            countriesLoaded,
            countriesError,
            countriesList,
            countriesState,
            handleDeliveryCountriesStateUpdate,
            (item: DeliveryCountryItemData) => ({
                label: item.delivery_country,
                id: item.pk as string,
            }),
        );
        preprocessForBusinessData<DeliveryLanguageItemData>(
            instructor?.delivery_languages,
            languagesLoaded,
            languagesError,
            languagesList,
            languagesState,
            handleDeliveryLanguagesStateUpdate,
            (item: DeliveryLanguageItemData) => ({
                label: item.delivery_language,
                id: item.pk as string,
            }),
        );
        preprocessForBusinessData<DomainSkillItemData>(
            instructor?.domain_skills,
            domainSkillsLoaded,
            domainSkillsError,
            domainSkillsList,
            domainSkillsState,
            handleDomainSkillsStateUpdate,
            (item: DomainSkillItemData) => ({
                label: item.domain_skill,
                id: item.pk as string,
            }),
        );
        preprocessForBusinessData<InstructorCourseStatusItemData>(
            null,
            courseStatusesLoaded,
            courseStatusesError,
            courseStatusesList,
            courseStatusesState,
            handleInstructorCourseStatusesStateUpdate,
            (item: InstructorCourseStatusItemData) => ({
                label: item.instructor_course_status,
                id: item.pk as string,
            }),
        );
        preprocessForBusinessData<CourseItemData>(
            null,
            coursesLoaded,
            coursesError,
            coursesList,
            coursesState,
            handleCoursesStateUpdate,
            (item: CourseItemData) => ({
                value: item.course,
            }),
        );
    })();

    if (instructorError) {
        return (
            <Alert
                header="Error"
                type="error"
                data-testid="InstructorEditSkillsAndCoursesError"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (instructor) {
        // IIFE for initialize(pre-populating) instructor profile data
        (() => {
            if (checkboxStatesInitialized) {
                setCheckboxStatesInitialized(false);
                const speakerCertification = instructor.speaker_certified
                    ? instructor.speaker_certified
                    : NOT_SPEAKER_CERTIFIED;
                setVILT(!!instructor.vilt);
                setPrCertified(!!instructor.pr_certified);
                setSpeakerCertifiedSelectedOption({
                    id: speakerCertification,
                    label: speakerCertification,
                });
                setChampion(!!instructor.champion);
                setMentor(!!instructor.mentor);
            }

            // if the selected options are not initialized, and business data lookup and options are generated,
            // initialize the selected options from instructor profile data
            if (
                countriesState.status === 'finished' &&
                !countriesState.selectedOptionsInitialized &&
                countriesState.selectLookup
            ) {
                if (instructor.delivery_countries) {
                    handleDeliveryCountriesStateUpdate({
                        selectedOptionsInitialized: true,
                        selectedOptions: generateSelectedOptions(
                            instructor.delivery_countries,
                            countriesState.selectLookup,
                        ),
                    });
                } else {
                    handleDeliveryCountriesStateUpdate({
                        selectedOptionsInitialized: true,
                    });
                }
            }
            if (
                languagesState.status === 'finished' &&
                !languagesState.selectedOptionsInitialized &&
                languagesState.selectLookup
            ) {
                if (instructor.delivery_languages) {
                    handleDeliveryLanguagesStateUpdate({
                        selectedOptionsInitialized: true,
                        selectedOptions: generateSelectedOptions(
                            instructor.delivery_languages,
                            languagesState.selectLookup,
                        ),
                    });
                } else {
                    handleDeliveryLanguagesStateUpdate({
                        selectedOptionsInitialized: true,
                    });
                }
            }
            if (
                domainSkillsState.status === 'finished' &&
                !domainSkillsState.selectedOptionsInitialized &&
                domainSkillsState.selectLookup
            ) {
                if (instructor.domain_skills) {
                    handleDomainSkillsStateUpdate({
                        selectedOptionsInitialized: true,
                        selectedOptions: generateSelectedOptions(
                            instructor.domain_skills,
                            domainSkillsState.selectLookup,
                        ),
                    });
                } else {
                    handleDomainSkillsStateUpdate({
                        selectedOptionsInitialized: true,
                    });
                }
            }

            if (!courseAttributeEditorItemsInitialized) {
                setCourseAttributeEditorItemsInitialized(true);
                if (instructor.courses) {
                    const { items, errors } = instructor.courses.reduce(
                        (acc, course, index) => {
                            acc.items.push({
                                nameValue: course.name,
                                pk: course.pk,
                                statusOption: {
                                    label: course.status,
                                    id: index.toString(),
                                },
                                previous_status: course.previous_status,
                                geoLeadValue:
                                    course.geo_lead !== undefined
                                        ? course.geo_lead
                                        : null,
                                regionalLeadValue:
                                    course.regional_lead !== undefined
                                        ? course.regional_lead
                                        : null,
                                coTeach: course.co_teach
                                    ? course.co_teach
                                    : null,
                                shadow: course.shadow ? course.shadow : null,
                            });
                            acc.errors.push({
                                name: null,
                                status: null,
                            });

                            return acc;
                        },
                        {
                            items: [],
                            errors: [],
                        } as {
                            items: Array<CourseAttributeEditorItem>;
                            errors: Array<CourseAttributeEditorItemError>;
                        },
                    );

                    setCourseAttributeEditorItems(items);
                    setCourseAttributeEditorItemErrors(errors);
                }
            }

            if (!candidateItemsInitialized) {
                setCandidateItemsInitialized(true);
                if (instructor.certmetrics_candidate_ids) {
                    setCandidateItems(
                        instructor.certmetrics_candidate_ids.slice(),
                    );
                }
            }
        })();

        const skillsSection = (
            <FormSection data-testid="SkillsSection" header="Skills">
                <FormField>
                    <Checkbox
                        data-testid="VILTCheckbox"
                        onChange={({ detail: { checked } }) => setVILT(checked)}
                        checked={vILT}
                    >
                        Approved to teach vILT courses
                    </Checkbox>
                </FormField>
                <FormField>
                    <Checkbox
                        data-testid="PrCertifiedCheckbox"
                        onChange={({ detail: { checked } }) =>
                            setPrCertified(checked)
                        }
                        checked={prCertified}
                    >
                        PR certified
                    </Checkbox>
                </FormField>
                <FormField>
                    <Checkbox
                        data-testid="ChampionCheckbox"
                        onChange={({ detail: { checked } }) =>
                            setChampion(checked)
                        }
                        checked={champion}
                    >
                        Champion
                    </Checkbox>
                </FormField>
                <FormField>
                    <Checkbox
                        data-testid="MentorCheckbox"
                        onChange={({ detail: { checked } }) =>
                            setMentor(checked)
                        }
                        checked={mentor}
                    >
                        Mentor
                    </Checkbox>
                </FormField>
                <div className="awsui-util-mb-l" />
                <FormField label="Speaker Certification">
                    <Select
                        data-testid="SpeakerCertificationSelect"
                        options={speakerCertifiedOptions}
                        selectedOption={speakerCertifiedSelectedOption}
                        onChange={({ detail }) => {
                            setSpeakerCertifiedSelectedOption(
                                detail.selectedOption,
                            );
                        }}
                    />
                </FormField>
                <FormField label="Countries authorized to teach in">
                    <Multiselect
                        data-testid="CountryMultiselect"
                        statusType={countriesState.status}
                        options={countriesState.selectOptions}
                        selectedOptions={countriesState.selectedOptions}
                        onChange={({ detail: { selectedOptions } }) => {
                            handleDeliveryCountriesStateUpdate({
                                selectedOptions: selectedOptions,
                            });
                        }}
                        onRecoveryClick={() => {
                            dispatch(resetPartialDeliveryCountriesSlice());
                        }}
                        placeholder={
                            countriesState.status === 'loading'
                                ? 'Loading countries'
                                : 'Select countries'
                        }
                        loadingText="Loading countries"
                        errorText="Error fetching countries."
                        recoveryText="Retry"
                        empty="No options"
                        checkboxes={true}
                        disabled={countriesState.status === 'loading'}
                    />
                </FormField>
                <FormField label="Languages spoken">
                    <Multiselect
                        data-testid="LanguagesMultiselect"
                        statusType={languagesState.status}
                        options={languagesState.selectOptions}
                        selectedOptions={languagesState.selectedOptions}
                        onChange={({ detail: { selectedOptions } }) => {
                            handleDeliveryLanguagesStateUpdate({
                                selectedOptions: selectedOptions,
                            });
                        }}
                        onRecoveryClick={() => {
                            dispatch(resetDeliveryLanguagesSlice());
                        }}
                        placeholder={
                            languagesState.status === 'loading'
                                ? 'Loading languages'
                                : 'Select languages'
                        }
                        loadingText="Loading languages"
                        errorText="Error fetching languages."
                        recoveryText="Retry"
                        empty="No options"
                        checkboxes={true}
                        disabled={languagesState.status === 'loading'}
                    />
                </FormField>
                <FormField label="Domain skills">
                    <Multiselect
                        data-testid="DomainSkillsMultiselect"
                        statusType={domainSkillsState.status}
                        options={domainSkillsState.selectOptions}
                        selectedOptions={domainSkillsState.selectedOptions}
                        onChange={({ detail: { selectedOptions } }) => {
                            handleDomainSkillsStateUpdate({
                                selectedOptions: selectedOptions,
                            });
                        }}
                        onRecoveryClick={() => {
                            dispatch(resetDomainSkillsSlice());
                        }}
                        placeholder={
                            domainSkillsState.status === 'loading'
                                ? 'Loading domain skills'
                                : 'Select domain skills'
                        }
                        loadingText="Loading domain skills"
                        errorText="Error fetching domain skills."
                        recoveryText="Retry"
                        empty="No options"
                        checkboxes={true}
                        disabled={domainSkillsState.status === 'loading'}
                    />
                </FormField>
            </FormSection>
        );

        const generateCandidateIdItems = () => {
            if (candidateItems) {
                return (
                    <React.Fragment>
                        {candidateItems.map((item, index) => {
                            const generator =
                                candidateIdItemLookup[item.status];
                            if (generator !== undefined) {
                                return generator(item, index);
                            } else {
                                return null;
                            }
                        })}
                    </React.Fragment>
                );
            } else {
                return null;
            }
        };

        const invalidCandidateIdInput = () => {
            if (!candidateItemID) {
                return true;
            } else {
                return new Set(candidateItems.map((item) => item.id)).has(
                    `${candidateItemID}`,
                );
            }
        };

        const handleRemoveCandidateIDConfirm = async () => {
            setIsSubmitting(true);
            if (
                await submitData({
                    certmetrics_candidate_ids: null,
                })
            ) {
                // Make sure the data consistency. Will need to update this
                // when BE fixes high latency of the API calls
                await dispatch(getSelectedInstructor(match.params.id));
                setCandidateItems([]);
                setCandidateItemsRemoveModalVisible(false);
            }
            setIsSubmitting(false);
        };

        const candidateIdsSection = (
            <FormSection
                data-testid="CandidateIdsSection"
                header="Certifications"
            >
                <ColumnLayout columns={1} borders="horizontal">
                    <div data-awsui-column-layout-root="true">
                        <FormField
                            label="Certmetrics Candidate ID"
                            description="Once you save this ID, we will fetch the certificates available to approve courses for this instructor."
                        >
                            <div className="awsui-row">
                                <Input
                                    data-testid="CandidateIdInput"
                                    className="col-m-10"
                                    value={candidateItemID}
                                    onInput={({ detail: { value } }) => {
                                        setCandidateItemID(value);
                                    }}
                                />
                                <Button
                                    data-testid="CandidateIdAddButton"
                                    className="col-m-2"
                                    disabled={invalidCandidateIdInput()}
                                    onClick={() => {
                                        setCandidateItems([
                                            ...candidateItems,
                                            {
                                                id: `${candidateItemID}`,
                                                status: 'fetching',
                                            },
                                        ]);
                                        setCandidateItemID('');
                                    }}
                                >
                                    Add
                                </Button>
                            </div>
                        </FormField>

                        <div>
                            {generateCandidateIdItems()}
                            <div className="awsui-util-mb-m" />
                            <Button
                                data-testid="RemoveCandidateIdButton"
                                disabled={!candidateItems.length}
                                onClick={() => {
                                    setCandidateItemsRemoveModalVisible(true);
                                }}
                            >
                                Remove Candidate IDs
                            </Button>
                            <Modal
                                visible={candidateItemsRemoveModalVisible}
                                header="Remove candidate IDs and associated certifications?"
                                footer={
                                    <span className="awsui-util-f-r">
                                        <Button
                                            data-testid="RemoveCandidateIdCancelButton"
                                            variant="link"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setCandidateItemsRemoveModalVisible(
                                                    false,
                                                );
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            data-testid="RemoveCandidateIdsButton"
                                            variant="primary"
                                            disabled={isSubmitting}
                                            onClick={
                                                handleRemoveCandidateIDConfirm
                                            }
                                        >
                                            {isSubmitting
                                                ? 'Removing Candidate IDs'
                                                : 'Remove Candidate IDs'}
                                        </Button>
                                    </span>
                                }
                                onDismiss={() => {
                                    setCandidateItemsRemoveModalVisible(false);
                                }}
                            >
                                <Alert type="warning">
                                    Removing candidate IDs will remove all the
                                    certifications associated with this
                                    instructor. Do you want to proceed?
                                </Alert>
                            </Modal>
                        </div>
                    </div>
                </ColumnLayout>
            </FormSection>
        );

        const generateFilteredAutosuggestOptions = (exception: string) => {
            const nameSet = new Set(
                courseAttributeEditorItems.map(
                    (item: CourseAttributeEditorItem) => item.nameValue,
                ),
            );
            return coursesState.autosuggestOptions?.filter(
                (option) =>
                    !nameSet.has(option.value) || option.value === exception,
            );
        };

        const handleCourseAdd = (
            value: string,
            addedCourseItem: CourseAttributeEditorItem,
            index: number,
        ) => {
            const newItems = courseAttributeEditorItems.slice();
            let courseId = '';
            if (value && value !== '') {
                for (let idx = 0; idx < coursesList.length; idx++) {
                    const course = coursesList[idx];
                    if (course.course === value && course.pk) {
                        courseId = course.pk;
                        break;
                    }
                }
                newItems.splice(index, 1, {
                    ...addedCourseItem,
                    nameValue: value,
                    pk: courseId,
                });
                setCourseAttributeEditorItems(newItems);
            }
        };

        const multiRowAttributeEditorDefinitions: Array<
            RowDefinition<CourseAttributeEditorItem, CourseRowId>
        > = [
            {
                rowId: CourseRowId.RowOne,
                fieldDefinitions: [
                    {
                        label: 'Course name',
                        colspan: 9,
                        control: (
                            addedCourseItem: CourseAttributeEditorItem,
                            index: number,
                        ) => (
                            <Autosuggest
                                data-testid={`CourseItemAutosuggest-${index.toString()}`}
                                value={addedCourseItem.nameValue}
                                statusType={coursesState.status}
                                options={generateFilteredAutosuggestOptions(
                                    addedCourseItem.nameValue as string,
                                )}
                                onInput={({ detail: { value } }) => {
                                    handleCourseAdd(
                                        value,
                                        addedCourseItem,
                                        index,
                                    );
                                }}
                                onRecoveryClick={() => {
                                    dispatch(resetPartialCoursesSlice());
                                }}
                                placeholder={
                                    coursesState.status === 'loading'
                                        ? 'Loading course names'
                                        : 'Enter course name'
                                }
                                loadingText="Loading course names"
                                errorText="Error fetching course names."
                                recoveryText="Retry"
                                empty="No matching courses found"
                                disabled={coursesState.status === 'loading'}
                            />
                        ),
                        errorText: (
                            addedCourseItem: CourseAttributeEditorItem,
                            index: number,
                        ) =>
                            courseAttributeEditorItemErrors[index]?.name ||
                            null,
                    },
                    {
                        label: 'Course status',
                        colspan: 3,
                        control: (
                            addedCourseItem: CourseAttributeEditorItem,
                            index: number,
                        ) => (
                            <Select
                                data-testid={`CourseItemSelect-${index.toString()}`}
                                statusType={courseStatusesState.status}
                                options={courseStatusesState.selectOptions}
                                selectedOption={addedCourseItem.statusOption}
                                onChange={({ detail: { selectedOption } }) => {
                                    const newItems =
                                        courseAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...addedCourseItem,
                                        statusOption: selectedOption,
                                    });
                                    setCourseAttributeEditorItems(newItems);
                                }}
                                onRecoveryClick={() => {
                                    dispatch(
                                        resetInstructorCourseStatusesSlice(),
                                    );
                                }}
                                placeholder={
                                    courseStatusesState.status === 'loading'
                                        ? 'Loading statuses'
                                        : 'Select a status'
                                }
                                loadingText="Loading statuses"
                                errorText="Error fetching statuses."
                                recoveryText="Retry"
                                empty="No options"
                                disabled={
                                    courseStatusesState.status === 'loading'
                                }
                            />
                        ),
                        errorText: (
                            addedCourseItem: CourseAttributeEditorItem,
                            index: number,
                        ) =>
                            courseAttributeEditorItemErrors[index]?.status ||
                            null,
                    },
                ],
            },
            {
                rowId: CourseRowId.RowTwo,
                fieldDefinitions: [
                    {
                        label: 'Shadow date',
                        colspan: 3,
                        control: (
                            addedCourseItem: CourseAttributeEditorItem,
                            index: number,
                        ) => (
                            <DatePicker
                                data-testid={`CourseItemShadowDate-${index.toString()}`}
                                placeholder="YYYY/MM/DD"
                                todayLabel="Today"
                                nextMonthLabel="Next month"
                                previousMonthLabel="Previous month"
                                value={
                                    addedCourseItem.shadow
                                        ? getISOString(
                                              addedCourseItem.shadow * 1000,
                                          )
                                        : ''
                                }
                                onChange={({ detail: { value } }) => {
                                    const newItems =
                                        courseAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...addedCourseItem,
                                        shadow: getSeconds(value),
                                    });
                                    setCourseAttributeEditorItems(newItems);
                                }}
                            />
                        ),
                    },
                    {
                        label: 'Co-teach date',
                        colspan: 3,
                        control: (
                            addedCourseItem: CourseAttributeEditorItem,
                            index: number,
                        ) => (
                            <DatePicker
                                data-testid={`CourseItemCoTeachDate-${index.toString()}`}
                                placeholder="YYYY/MM/DD"
                                todayLabel="Today"
                                nextMonthLabel="Next month"
                                previousMonthLabel="Previous month"
                                value={
                                    addedCourseItem.coTeach
                                        ? getISOString(
                                              addedCourseItem.coTeach * 1000,
                                          )
                                        : ''
                                }
                                onChange={({ detail: { value } }) => {
                                    const newItems =
                                        courseAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...addedCourseItem,
                                        coTeach: getSeconds(value),
                                    });
                                    setCourseAttributeEditorItems(newItems);
                                }}
                            />
                        ),
                    },
                ],
            },
            {
                rowId: CourseRowId.RowThree,
                fieldDefinitions: [
                    {
                        label: '',
                        colspan: 12,
                        control: (
                            addedCourseItem: CourseAttributeEditorItem,
                            index: number,
                        ) => (
                            <>
                                <div>
                                    <Checkbox
                                        checked={
                                            !!addedCourseItem?.geoLeadValue
                                        }
                                        data-testid={`CourseItemGeoCheckbox-${index.toString()}`}
                                        onChange={({ detail: { checked } }) => {
                                            const newItems =
                                                courseAttributeEditorItems.slice();
                                            newItems.splice(index, 1, {
                                                ...addedCourseItem,
                                                geoLeadValue: checked,
                                            });
                                            setCourseAttributeEditorItems(
                                                newItems,
                                            );
                                        }}
                                    >
                                        Global lead
                                    </Checkbox>
                                </div>
                                <div>
                                    <Checkbox
                                        checked={
                                            !!addedCourseItem?.regionalLeadValue
                                        }
                                        data-testid={`CourseItemRegionalCheckbox-${index.toString()}`}
                                        onChange={({ detail: { checked } }) => {
                                            const newItems =
                                                courseAttributeEditorItems.slice();
                                            newItems.splice(index, 1, {
                                                ...addedCourseItem,
                                                regionalLeadValue: checked,
                                            });
                                            setCourseAttributeEditorItems(
                                                newItems,
                                            );
                                        }}
                                    >
                                        Regional lead
                                    </Checkbox>
                                </div>
                            </>
                        ),
                    },
                ],
            },
        ];

        const onRemoveButtonClick = (itemIndex: number) => {
            const newItems = courseAttributeEditorItems.slice();
            newItems.splice(itemIndex, 1);
            setCourseAttributeEditorItems(newItems);
            const newErrors = courseAttributeEditorItemErrors.slice();
            newErrors.splice(itemIndex, 1);
            setCourseAttributeEditorItemErrors(newErrors);
        };

        const onAddButtonClick = () => {
            setCourseAttributeEditorItems([
                ...courseAttributeEditorItems,
                {
                    nameValue: null,
                    pk: null,
                    statusOption: null,
                    geoLeadValue: null,
                    regionalLeadValue: null,
                } as CourseAttributeEditorItem,
            ]);
            setCourseAttributeEditorItemErrors([
                ...courseAttributeEditorItemErrors,
                {
                    name: null,
                    status: null,
                } as CourseAttributeEditorItemError,
            ]);
        };

        const hideRow = (
            editorRow: RowDefinition<CourseAttributeEditorItem, CourseRowId>,
            item: CourseAttributeEditorItem,
        ) =>
            editorRow.rowId === CourseRowId.RowTwo &&
            item.statusOption?.label !== RAMPING_STATUS_VALUE;

        const coursesSection = (
            <FormSection
                data-testid="CoursesSection"
                className="courses-section"
                header="Courses"
            >
                <MultiRowAttributeEditor
                    data-testid="CoursesMultiRowAttributeEditor"
                    empty="No courses have been added"
                    addButtonText="Add course"
                    removeButtonText="Remove"
                    rowDefinitions={multiRowAttributeEditorDefinitions}
                    items={courseAttributeEditorItems}
                    onAddButtonClick={onAddButtonClick}
                    onRemoveButtonClick={onRemoveButtonClick}
                    hideRow={hideRow}
                />
            </FormSection>
        );

        const generateProfileUpdateData = () => {
            const data: Partial<InstructorProfileData> = {};

            data.vilt = vILT;
            data.pr_certified = prCertified;
            data.speaker_certified = speakerCertifiedSelectedOption?.label;
            data.champion = champion;
            data.mentor = mentor;

            // update the data only if business data is successfully retrieved
            if (
                countriesState.status === 'finished' &&
                countriesState.selectedOptions
            ) {
                data.delivery_countries = countriesState.selectedOptions.map(
                    (option) => option.label,
                );
            }
            if (
                languagesState.status === 'finished' &&
                languagesState.selectedOptions
            ) {
                data.delivery_languages = languagesState.selectedOptions.map(
                    (option) => option.label,
                );
            }
            if (
                domainSkillsState.status === 'finished' &&
                domainSkillsState.selectedOptions
            ) {
                data.domain_skills = domainSkillsState.selectedOptions.map(
                    (option) => option.label,
                );
            }

            // since this method is called after the form data is successfully validated,
            // directly generate the data
            data.courses = courseAttributeEditorItems.map(
                (item: CourseAttributeEditorItem) => ({
                    name: item.nameValue as string,
                    pk: item.pk as string,
                    active: true,
                    status: item.statusOption?.label as string,
                    previous_status: item.previous_status,
                    regional_lead: item.regionalLeadValue,
                    geo_lead: item.geoLeadValue,
                    co_teach: item.coTeach,
                    shadow: item.shadow,
                }),
            );

            data.certmetrics_candidate_ids = candidateItems;

            return data;
        };

        const submitData = async (
            data: Partial<InstructorProfileData>,
        ): Promise<boolean> => {
            const isSuccessful = await handleInstructorDetailNotification(
                dispatch<any>(updateSelectedInstructor(match.params.id, data)),
                addNotification,
                'skills-and-courses',
            );

            if (isSuccessful) {
                dispatch(setRequireUpdate(true));
            }
            return isSuccessful;
        };

        const navigateToDetailPage = () => {
            history.push({
                pathname: `/instructors/${match.params.id}`,
            });
        };

        const handleSaveConfirm = async () => {
            setIsSubmitting(true);
            if (
                validateFormData(
                    courseAttributeEditorItems,
                    setCourseAttributeEditorItemErrors,
                    coursesState,
                    courseStatusesState,
                ) &&
                (await submitData(generateProfileUpdateData()))
            ) {
                navigateToDetailPage();
            } else {
                setIsSubmitting(false);
            }
        };

        const cancelModalProps: CancelModalProps = {
            isCancelModalVisible,
            setIsCancelModalVisible,
            isSubmitting,
            onCancelConfirm: navigateToDetailPage,
            testPrefix: 'EditSkillsAndCourses',
        };

        return (
            <div data-testid="InstructorEditSkillsAndCoursesPage">
                <div className="awsui-util-action-stripe-large">
                    <div className="awsui-util-action-stripe-title awsui-util-mb-m">
                        <h1>Edit skills and courses</h1>
                    </div>
                </div>
                <Form
                    actions={
                        <div>
                            <Button
                                data-testid="FormCancelButton"
                                variant="link"
                                disabled={isSubmitting}
                                onClick={() => {
                                    setIsCancelModalVisible(true);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid="SubmitButton"
                                variant="primary"
                                disabled={isSubmitting}
                                onClick={handleSaveConfirm}
                            >
                                {isSubmitting ? 'Saving' : 'Save'}
                            </Button>
                        </div>
                    }
                >
                    {skillsSection}
                    {candidateIdsSection}
                    {coursesSection}
                </Form>
                <CancelModal {...cancelModalProps} />
            </div>
        );
    } else {
        return <Spinner data-testid="InstructorEditSkillsAndCoursesSpinner" />;
    }
};

export default EditSkillsAndCourses;
