import { ActivityGroupData } from '../../../../interfaces/activityGroup';
import { ActivityStatus } from '../../Common/Common';

export enum AciResouceConstrain {
    MAX_LAG_DURATION_YEAR = 2,
    MAX_LAG_INSTRUCTOR_POOL_SIZE = 5,
    MAX_ACTIVITY_CLASS_SIZE = 500,
    MAX_ACTIVITY_PER_LAG = 5,
    MAX_DELIVERY_SESSION_PER_ACTIVITY = 7,
    MAX_INSTRUCTOR_SEARCH_RESULTS = 20,
}

export const AciMessage = {
    MAX_LAG_DURATION_YEAR: `The selected period is too long (maximum: ${AciResouceConstrain.MAX_LAG_DURATION_YEAR} year).`,
    MAX_LAG_INSTRUCTOR_POOL_SIZE: `Instructor pool limit: ${AciResouceConstrain.MAX_LAG_INSTRUCTOR_POOL_SIZE} reached.`,
    MAX_ACTIVITY_CLASS_SIZE: `Class Size should be less than ${AciResouceConstrain.MAX_ACTIVITY_CLASS_SIZE}.`,
    MAX_ACTIVITY_PER_LAG: `Activity Group capacity exceeded: ${AciResouceConstrain.MAX_ACTIVITY_PER_LAG} activities maximum.`,
    MAX_DELIVERY_SESSION_PER_ACTIVITY: `Activity limit: maximum ${AciResouceConstrain.MAX_DELIVERY_SESSION_PER_ACTIVITY} delivery sessions allowed.`,
    INVALID_LAG_DATE: `Start date must be before end date.`,
};

const SECONDS_PER_DAY = 86400;
const DAYS_PER_YEAR = 365;
const MAX_ACTIVITY_GROUP_DURATION_SEC =
    AciResouceConstrain.MAX_LAG_DURATION_YEAR * SECONDS_PER_DAY * DAYS_PER_YEAR;

type activityGroupCreateValidations =
    | 'groupName'
    | 'dateRange'
    | 'inValidateDateRange'
    | 'emptyProgram'
    | 'emptyCourse';
type activityGroupUpdateValidations =
    | 'location'
    | 'childDeliverySessions'
    | 'childAndParentStatus'
    | 'classSize';

type activityGroupValidations<T extends string> = {
    [validationName in T]: {
        isValid: (data: ActivityGroupData) => boolean;
        invalidMessage: string;
    };
};

export const createValidations: activityGroupValidations<activityGroupCreateValidations> =
    {
        groupName: {
            isValid: (data) => /^[a-zA-Z0-9-_:.\s]{10,100}$/.test(data.name),
            invalidMessage:
                'Activity group name must be between 10-100 supported characters (supported: a-zA-Z, 0-9, spaces, :_-)',
        },
        dateRange: {
            isValid: ({ start_timestamp, end_timestamp }) =>
                end_timestamp - start_timestamp <
                MAX_ACTIVITY_GROUP_DURATION_SEC,
            invalidMessage: AciMessage.MAX_LAG_DURATION_YEAR,
        },
        inValidateDateRange: {
            isValid: ({ start_timestamp, end_timestamp }) =>
                end_timestamp > start_timestamp,
            invalidMessage: AciMessage.INVALID_LAG_DATE,
        },
        emptyProgram: {
            isValid: ({ program_name }) => program_name.length > 0,
            invalidMessage: 'Please select Program',
        },
        emptyCourse: {
            isValid: ({ catalog_item_id }) => catalog_item_id.length > 0,
            invalidMessage: 'Please select Course',
        },
    };

export const updateValidations: activityGroupValidations<activityGroupUpdateValidations> =
    {
        location: {
            isValid: (data) =>
                !!(
                    data.child_activities.length &&
                    data.child_activities[0]?.delivery_city
                ),
            invalidMessage: 'Delivery location must be set before saving',
        },
        childDeliverySessions: {
            isValid: (data) =>
                data.child_activities.every(
                    (ca) => ca.delivery_sessions.length,
                ),
            invalidMessage:
                'All child activities must have at least one delivery session',
        },
        childAndParentStatus: {
            isValid: (data) => {
                const newActivities = data.child_activities.filter(
                    (ca) => !ca.pk,
                );
                return newActivities.every((a) =>
                    [ActivityStatus.Hold, ActivityStatus.Tentative].includes(
                        a.activity_status,
                    ),
                );
            },
            invalidMessage:
                'Options have been added when status is not Hold/Tentative. Set status back to Hold/Tentative or remove the new Options.',
        },
        classSize: {
            isValid: (data) =>
                data.child_activities.every((ca) => ca.class_size <= 500),
            invalidMessage: AciMessage.MAX_ACTIVITY_CLASS_SIZE,
        },
    };
