import { Button } from '@amzn/awsui-components-react-v3';
import { ActivityData, ClassroomStatus } from '../../../../interfaces/activity';
import useClassroomManagement from '../../Common/useClassroomManagement';
import {
    ActivityLMSType,
    ActivityModality,
    ActivityProgram,
    ActivityStatus,
} from '../../Common/Common';

export const CreateClassroomButton = ({
    activity,
}: {
    activity: ActivityData;
}) => {
    const { handleSubmitEvent, submitting } = useClassroomManagement();
    const { classrooms_status: classroomStatus } = activity;

    let isCreatingClassroom =
        submitting ||
        classroomStatus === ClassroomStatus.READY_FOR_CLASSROOM_CREATION;

    return (
        <Button
            data-testid="create-classroom-button"
            disabled={
                !isActivityClassroomsAPIEnabled(activity) ||
                isClassroomStatusPresent(activity)
            }
            iconName={
                classroomStatus === ClassroomStatus.CLASSROOM_READY
                    ? 'status-positive'
                    : null
            }
            onClick={() => {
                handleSubmitEvent();
            }}
            loading={isCreatingClassroom}
        >
            {getButtonText(activity, isCreatingClassroom)}
        </Button>
    );
};

const isActivityClassroomsAPIEnabled = (activity: ActivityData): boolean => {
    const {
        activity_status: activityStatus,
        activity_modality: activityModality,
        classrooms_status: classroomStatus,
        program: activityProgram,
        class_size: classSize,
        v_ilt_id: vIltId,
        v_ilt_meeting_id: vIltMeetingId,
        lms_type: lmsType,
    } = activity;
    const hasEligibleCourse =
        classroomStatus ===
        ClassroomStatus.COURSE_ALLOWED_FOR_CLASSROOM_CREATION;
    const isILTModality = activityModality === ActivityModality.ILT;
    const isActivityActive = activityStatus === ActivityStatus.Active;
    const isProgramCommercial = activityProgram === ActivityProgram.Commercial;
    const isClassSizeValid = classSize >= 1;
    const isKiku = lmsType === ActivityLMSType.Kiku;
    const isCustomerLms = lmsType === ActivityLMSType.CustomerLMS;
    const hasVIltId = Boolean(vIltId);
    const hasVIltMeetingId = Boolean(vIltMeetingId);

    return (
        isActivityActive &&
        isProgramCommercial &&
        hasEligibleCourse &&
        isClassSizeValid &&
        (isILTModality ||
            (isKiku && hasVIltId) ||
            (isCustomerLms && hasVIltId && hasVIltMeetingId))
    );
};

const isClassroomStatusPresent = (activity: ActivityData): boolean => {
    const { classrooms_status: classroomStatus } = activity;

    const validClassroomStatus = [
        ClassroomStatus.CLASSROOM_UPDATE_FAILED,
        ClassroomStatus.CANCELLED,
        ClassroomStatus.CLASSROOM_PROVISIONING_FAILED,
        ClassroomStatus.CLASSROOM_READY,
        ClassroomStatus.READY_FOR_CLASSROOM_CREATION,
    ];

    return validClassroomStatus.includes(classroomStatus as ClassroomStatus);
};

const getButtonText = (
    activity: ActivityData,
    isCreatingClassroom: boolean,
) => {
    const { classrooms_status: classroomStatus } = activity;
    if (isCreatingClassroom) {
        return 'Creating Classroom';
    } else if (
        classroomStatus === ClassroomStatus.CLASSROOM_READY ||
        classroomStatus === ClassroomStatus.CANCELLED ||
        classroomStatus === ClassroomStatus.CLASSROOM_UPDATE_FAILED
    ) {
        return 'Classroom Created';
    } else {
        return 'Create Classroom';
    }
};
