import React, { createContext, useCallback, useContext, useState } from 'react';
import { useFeatureFlag } from '../hooks/useFeatureFlag';

interface HelpPanelContextType {
    state: {
        toolsOpen: boolean;
        helpPanelTopic: string;
    };
    actions: {
        setHelpPanelTopic(helpPanelTopic: string): void;
        setToolsOpen(toolsOpen: boolean): void;
        makeHelpPanelHandler(topic: string): () => void;
    };
}

const HelpPanelContext = createContext<HelpPanelContextType | undefined>(
    undefined,
);

export function useHelpPanel() {
    const context = useContext(HelpPanelContext);
    if (!context) {
        throw new Error('useHelpPanel must be used within a HelpPanelProvider');
    }

    const canShowHelpPanel = useFeatureFlag('enable-help-panel');
    const state = {
        ...context.state,
        helpPanelTopic: canShowHelpPanel ? context.state.helpPanelTopic : null,
    };

    return {
        state,
        actions: context.actions,
        canShowHelpPanel,
    };
}

export function HelpPanelProvider({ children }: { children: React.ReactNode }) {
    const [helpPanelTopic, setHelpPanelTopic] = useState(null);
    const [toolsOpen, setToolsOpen] = useState(true);

    const makeHelpPanelHandler = useCallback(
        (topic: string) => () => {
            setHelpPanelTopic(topic);
            setToolsOpen(true);
        },
        [],
    );
    const state = { helpPanelTopic, toolsOpen };

    const actions = {
        setHelpPanelTopic,
        setToolsOpen,
        makeHelpPanelHandler,
    };

    return (
        <HelpPanelContext.Provider value={{ state, actions }}>
            {children}
        </HelpPanelContext.Provider>
    );
}
