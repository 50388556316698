import { Button, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { Dispatch, SetStateAction } from 'react';

interface FormActionsProps {
    isSubmitting: boolean;
    isSaveDisabled: boolean;
    saveDisabledReason?: string;
    setIsCancelModalVisible: Dispatch<SetStateAction<boolean>>;
    onSave: () => void;
    saveButtonText?: {
        default: string;
        saving: string;
    };
    cancelButtonText?: string;
}

const FormActions = ({
    isSubmitting,
    isSaveDisabled,
    saveDisabledReason,
    setIsCancelModalVisible,
    onSave,
    saveButtonText = {
        default: 'Save',
        saving: 'Saving',
    },
    cancelButtonText = 'Cancel',
}: FormActionsProps) => {
    return (
        <SpaceBetween direction="horizontal" size="xs">
            <Button
                data-testid={`activity-group-${cancelButtonText.toLowerCase()}-button`}
                formAction="none"
                variant="link"
                disabled={isSubmitting}
                onClick={() => {
                    setIsCancelModalVisible(true);
                }}
            >
                {cancelButtonText}
            </Button>
            <Button
                data-testid={`activity-group-${saveButtonText.default.toLowerCase()}-button`}
                variant="primary"
                formAction="submit"
                disabled={isSubmitting || isSaveDisabled}
                disabledReason={saveDisabledReason}
                loading={isSubmitting}
                onClick={onSave}
            >
                {isSubmitting ? saveButtonText.saving : saveButtonText.default}
            </Button>
        </SpaceBetween>
    );
};

export default FormActions;
