import {
    DateRangePicker,
    PropertyFilterProps,
} from '@amzn/awsui-components-react-v3';
import React, { useState } from 'react';
import { dateRangeFilterI18nStrings } from '../../../../../../common/utils/date-range-picker';
import { relativeOptions } from '../../../../../constants/relativeOptions';
import {
    getRelativeDateRange,
    getUnixTimeInTimezoneFromDatestring,
} from '../../../../../../common/utils/date-time.utils';
import { DATE_RANGE_FILTER_KEY } from '../../../Common/Common';
import { getValidRangeFunctionWithMaxDays } from '../../../../../common/getValidRangeFunctionWithMaxDays';
import { updateQueryTokens } from '../../utils/query-utils';

const ActivityGroupListCalendarFilter = ({
    setCombinedQuery,
    combinedQuery,
}: {
    setCombinedQuery: (query: PropertyFilterProps.Query) => void;
    combinedQuery: any;
}) => {
    const [value, setValue] = useState(undefined);

    const onDateRangeChange = ({ detail }) => {
        setValue(detail.value);

        // User clicked the clear button
        if (detail.value === null) {
            const newQuery = updateQueryTokens({
                combinedQueryTokens: combinedQuery.tokens,
                newTokens: null,
                shouldIncludeDateRange: false,
            });
            setCombinedQuery({
                ...combinedQuery,
                tokens: newQuery,
            });
            return;
        }

        let timeRange = {
            propertyKey: DATE_RANGE_FILTER_KEY,
            operator: '=',
            value: {},
        };

        if (detail.value.type === 'absolute') {
            timeRange.value = {
                starts_after: getUnixTimeInTimezoneFromDatestring({
                    datestring: detail.value.startDate,
                }),
                ends_before: getUnixTimeInTimezoneFromDatestring({
                    datestring: detail.value.endDate,
                }),
            };
        } else if (detail.value.type === 'relative') {
            timeRange.value = getRelativeDateRange({
                relativeValue: detail.value,
            });
        }

        const newQuery = updateQueryTokens({
            combinedQueryTokens: combinedQuery.tokens,
            newTokens: [timeRange],
            shouldIncludeDateRange: false,
        });
        setCombinedQuery({
            ...combinedQuery,
            tokens: newQuery,
        });
    };

    return (
        <DateRangePicker
            onChange={onDateRangeChange}
            value={value}
            isValidRange={getValidRangeFunctionWithMaxDays({
                maxAllowedDays: 366,
            })}
            relativeOptions={relativeOptions}
            i18nStrings={dateRangeFilterI18nStrings}
            placeholder="Filter by a date and time range"
        />
    );
};

export default ActivityGroupListCalendarFilter;
