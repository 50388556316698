import { CopyToClipboard, Link } from '@amzn/awsui-components-react-v3';
import { V_ILT_TYPE } from '../../../../../constants';
import {
    formatDeliverySessionTime,
    formatGrimsbyDate,
} from '../../../Common/Common';

export const sessionTableColumns = [
    {
        id: 'activity_name',
        header: 'Delivery',
        cell: (item) => item.activityName,
    },
    {
        id: 'session_type',
        header: 'Session Type',
        cell: (item) => item.delivery_session_type,
        sortingField: 'delivery_session_type',
    },
    {
        id: 'date',
        header: 'Date',
        cell: (item) => formatGrimsbyDate(item.start_timestamp, item.timezone),
        sortingField: 'start_timestamp',
    },
    {
        id: 'time',
        header: 'Time',
        cell: (item) => formatDeliverySessionTime(item, item.timezone),
        sortingField: 'start_timestamp',
    },
    {
        id: 'meetingType',
        header: 'Meeting Type',
        cell: (item) => item.v_ilt_info?.type || '-',
        sortingField: 'v_ilt_info.type',
    },
    {
        id: 'meetingId”',
        header: 'Meeting ID',
        cell: ({ v_ilt_info: { type, streamyard_url, url } }) => {
            const text =
                type === V_ILT_TYPE.PVILT
                    ? (streamyard_url ?? '-')
                    : (url ?? '-');

            if (text === '-') {
                return text;
            }

            return (
                <CopyToClipboard
                    copyButtonAriaLabel="Copy meeting ID"
                    copyErrorText="Meeting ID failed to copy"
                    copySuccessText="Meeting ID copied"
                    textToCopy={text}
                    variant="inline"
                />
            );
        },
        sortingField: 'v_ilt_info.type',
    },
    {
        id: 'name',
        header: 'Instructor(s)',
        cell: (item) => {
            return item.instructors.map((instructor, index, array) => {
                const separator = index === array.length - 1 ? '' : ', ';
                return (
                    <>
                        <Link external href={`/instructors/${instructor.pk}`}>
                            {instructor.name}
                        </Link>
                        {separator}
                    </>
                );
            });
        },
    },
];
