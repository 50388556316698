export const getActivityDetailsItems = (activity) => [
    {
        label: 'Delivery Location',
        value: `${activity.delivery_city || ''}, ${
            activity.delivery_state || ''
        }, ${activity.delivery_country || ''}`,
    },
    {
        label: 'Delivery Region',
        value: activity.delivery_region,
    },
    {
        label: 'Time Zone',
        value: activity.delivery_timezone,
    },
    {
        label: 'Delivery Modality',
        value: activity.activity_modality,
    },
    {
        label: 'Activity Type',
        value: activity.activity_type,
    },
    {
        label: 'Audience',
        value: activity.activity_audience,
    },
];
