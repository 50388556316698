import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Table,
    TableFiltering,
    TablePagination,
} from '@amzn/awsui-components-react';

import {
    initializeAtpCompaniesListQueryParams,
    resetAtpCompaniesSlice,
    getAtpCompaniesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllAtpCompanies,
    selectError,
    updateAtpCompany,
    selectSelectedAtpCompany,
    setSelectedAtpCompany,
    addAtpCompany,
    selectCount,
    setFrom,
    setCurrentPageIndex,
    setAtpCompaniesList,
    selectPagesCount,
    selectCurrentPageIndex,
    setSearchText,
} from '../../../common/store/slices/atpCompaniesSlice';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { AtpCompanyItemData } from '../../../common/interfaces/businessDataItem/atpCompanyItem';
import { GRIMSBY_PAGE_COUNT } from '../../../common/constants/grimsby';
import { AdminBusinessDataTableSSPaginationProps } from '../AdminBusinessData/AdminBusinessData.Table';
import AdminAtpCompanyForm, {
    AdminAtpCompanyFormSchema,
    FormDataTypes,
} from './AdminAtpCompanyForm';

const ATP_COMPANIES_TABLE_TITLE = 'Providers';
const PROVIDER_TYPES = {
    isAtp: 'ATP company',
    isReseller: 'Reseller',
    isFreelanceAgency: 'Freelance agency',
} as const;
export const ATP_COMPANIES_DISPLAY_SINGULAR = 'Provider';
export const ATP_COMPANIES_NAME_KEY = 'atp_company';
export const ATP_COMPANIES_SPMS_ID_KEY = 'spms_id';

export const formatProviderType = (atpCompany: AtpCompanyItemData) => {
    const types = [] as string[];

    atpCompany.is_atp && types.push(PROVIDER_TYPES.isAtp);
    atpCompany.is_reseller && types.push(PROVIDER_TYPES.isReseller);
    atpCompany.is_freelance_agency &&
        types.push(PROVIDER_TYPES.isFreelanceAgency);

    return types.join(', ');
};

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'apt_company_display_name',
        header: 'Provider display name',
        cell: (atpCompany: AtpCompanyItemData) =>
            atpCompany.atp_company_display_name,
    },
    {
        id: 'active',
        header: 'Status',
        cell: (atpCompany: AtpCompanyItemData) =>
            formatStatus(atpCompany.active.toString()),
    },
    {
        id: 'provider_type',
        header: 'Provider type',
        cell: (atpCompany: AtpCompanyItemData) =>
            formatProviderType(atpCompany),
    },
    {
        id: 'spms_id',
        header: 'SPMS ID',
        cell: (atpCompany: AtpCompanyItemData) => atpCompany.spms_id,
    },
    {
        id: 'gilmore_license_id',
        header: 'Gilmore ID',
        cell: (atpCompany: AtpCompanyItemData) => atpCompany.gilmore_license_id,
    },
    {
        id: 'funding_program_type',
        header: 'Funding Program Type',
        cell: (atpCompany: AtpCompanyItemData) =>
            atpCompany.funding_program_list?.join(','),
    },
];

// /**
//  * Editable form fields for AtpCompany
//  * The keys must map to AtpCompanyItemData properties
//  */
export const createFormSchema: AdminAtpCompanyFormSchema<AtpCompanyItemData> = {
    // the keys must match ProgramDataType properties
    atp_company: {
        required: true,
        disabled: false,
        type: FormDataTypes.STRING,
        defaultValue: '',
        formDataTransform: (val: string): Partial<AtpCompanyItemData> => {
            return { atp_company: val };
        },
    },
    atp_company_display_name: {
        required: false,
        disabled: false,
        type: FormDataTypes.STRING,
        defaultValue: '',
        formDataTransform: (val: string): Partial<AtpCompanyItemData> => {
            return { atp_company_display_name: val };
        },
    },
    active: {
        required: true,
        disabled: false,
        type: FormDataTypes.YESNO,
        defaultValue: true,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<AtpCompanyItemData> => {
            return { active: parseBoolean(val) };
        },
    },
    is_reseller: {
        required: false,
        disabled: false,
        type: FormDataTypes.BOOLEAN,
        defaultValue: false,
        formDataTransform: (
            val: boolean | undefined,
        ): Partial<AtpCompanyItemData> => {
            return { is_reseller: val };
        },
    },
    is_freelance_agency: {
        required: false,
        disabled: false,
        type: FormDataTypes.BOOLEAN,
        defaultValue: false,
        formDataTransform: (
            val: boolean | undefined,
        ): Partial<AtpCompanyItemData> => {
            return { is_freelance_agency: parseBoolean(val) };
        },
    },
    is_atp: {
        required: false,
        disabled: false,
        type: FormDataTypes.BOOLEAN,
        defaultValue: false,
        formDataTransform: (
            val: boolean | undefined,
        ): Partial<AtpCompanyItemData> => {
            return { is_atp: parseBoolean(val) };
        },
    },
    gilmore_license_id: {
        required: false,
        disabled: false,
        type: FormDataTypes.STRING,
        defaultValue: '',
        formDataTransform: (val: string): Partial<AtpCompanyItemData> => {
            return { gilmore_license_id: val };
        },
    },
    spms_id: {
        required: true,
        disabled: false,
        type: FormDataTypes.STRING,
        defaultValue: '',
        formDataTransform: (val: string): Partial<AtpCompanyItemData> => {
            return { spms_id: val };
        },
    },
    training_coordinators_emails: {
        required: false,
        disabled: false,
        type: FormDataTypes.LIST,
        defaultValue: null,
        formDataTransform: (list: string[]): Partial<AtpCompanyItemData> => {
            return { training_coordinators_emails: list };
        },
    },
    funding_program_list: {
        required: false,
        disabled: false,
        type: FormDataTypes.LIST,
        defaultValue: null,
        formDataTransform: (list: string[]): Partial<AtpCompanyItemData> => ({
            funding_program_list: list,
        }),
    },
};
// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminAtpCompanyFormSchema<AtpCompanyItemData> = {
    ...createFormSchema,
    atp_company: {
        ...createFormSchema.atp_company,
        disabled: false,
    },
};

const AdminAtpCompanies = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();
    const pagesCount: number = useSelector(selectPagesCount);
    const currentPageIndex: number = useSelector(selectCurrentPageIndex);
    const [creating, setCreating] = useState(false);
    const [updating, setUpdating] = useState(false);

    const items: AtpCompanyItemData[] = useSelector(selectAllAtpCompanies);
    const itemCount: number = useSelector(selectCount);
    const isLoading: boolean = useSelector(selectIsLoading);
    const isLoaded: boolean = useSelector(selectIsLoaded);
    const error = useSelector(selectError);
    const currentItem: AtpCompanyItemData | null = useSelector(
        selectSelectedAtpCompany,
    );

    const initialFormState = Object.entries(createFormSchema).reduce(
        (acc: any, [key, value]) => {
            acc[key] = value.defaultValue;
            return acc;
        },
        {},
    );

    function useAtpCompanies(): AdminBusinessDataSelectors<AtpCompanyItemData> {
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(
                initializeAtpCompaniesListQueryParams({
                    active: 'all',
                    size: GRIMSBY_PAGE_COUNT,
                }),
            );
            dispatch(getAtpCompaniesList());

            return () => {
                dispatch(resetAtpCompaniesSlice());
            };
        }, [dispatch]);
        return {
            items,
            itemCount,
            isLoading,
            isLoaded,
            error,
            currentItem,
        };
    }

    const handleItemSelect = async (atpCompany: AtpCompanyItemData) => {
        if (atpCompany) {
            dispatch(setSelectedAtpCompany(atpCompany.atp_company));
            await dispatch(getAtpCompaniesList());
        }
    };

    const handleItemUpdate = async (data: AtpCompanyItemData) => {
        dispatch(setSelectedAtpCompany(null));
        const dispatchPromise = dispatch<any>(
            updateAtpCompany(data, currentItem?.atp_company),
        );
        await handleBusinessDataNotification({
            businessDataType: ATP_COMPANIES_NAME_KEY,
            businessDataDisplayNameLower:
                ATP_COMPANIES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.atp_company,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async (data: AtpCompanyItemData) => {
        const dispatchPromise = dispatch<any>(addAtpCompany(data));
        await handleBusinessDataNotification({
            businessDataType: ATP_COMPANIES_NAME_KEY,
            businessDataDisplayNameLower:
                ATP_COMPANIES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.atp_company,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    const handleTablePaginationChange = async (
        event: CustomEvent<TablePagination.PaginationChangeDetail>,
    ) => {
        dispatch(setCurrentPageIndex(event.detail.currentPageIndex));
        const from = (event.detail.currentPageIndex - 1) * GRIMSBY_PAGE_COUNT;
        dispatch(setFrom(from));
        dispatch(getAtpCompaniesList());
    };

    const handleTableSearch = async (
        event: CustomEvent<TableFiltering.FilteringChangeDetail>,
    ) => {
        dispatch(setFrom(0));
        dispatch(setCurrentPageIndex(1));
        dispatch(
            setSearchText(
                event.detail.filteringText ? event.detail.filteringText : null,
            ),
        );
        dispatch(setAtpCompaniesList([]));
        dispatch(getAtpCompaniesList());
    };

    const serverSidePaginationProps: AdminBusinessDataTableSSPaginationProps = {
        onPaginationChange: handleTablePaginationChange,
        onFilteringDelayedInput: handleTableSearch,
        pagesCount: pagesCount,
        currentPageIndex: currentPageIndex,
    };

    return (
        <div data-testid="AdminBusinessDataManagementAtpCompanies">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createForm={
                    <AdminAtpCompanyForm
                        mode="Create"
                        initialState={initialFormState}
                        isModalOpen={creating}
                        setIsModalOpen={setCreating}
                        saveItem={handleItemCreate}
                        formSchema={createFormSchema}
                        displaySingular={ATP_COMPANIES_DISPLAY_SINGULAR}
                        nameKey={ATP_COMPANIES_NAME_KEY}
                    />
                }
                updateForm={
                    <AdminAtpCompanyForm
                        mode="Update"
                        initialState={currentItem ?? initialFormState}
                        isModalOpen={updating}
                        setIsModalOpen={setUpdating}
                        saveItem={handleItemUpdate}
                        formSchema={updateFormSchema}
                        displaySingular={ATP_COMPANIES_DISPLAY_SINGULAR}
                        nameKey={ATP_COMPANIES_NAME_KEY}
                    />
                }
                creatingFlag={creating}
                updatingFlag={updating}
                setCreatingFlag={setCreating}
                setUpdatingFlag={setUpdating}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={ATP_COMPANIES_DISPLAY_SINGULAR}
                itemNameKey={ATP_COMPANIES_NAME_KEY}
                title={ATP_COMPANIES_TABLE_TITLE}
                useItemList={useAtpCompanies}
                serverSidePaginationProps={serverSidePaginationProps}
            />
        </div>
    );
};

export default AdminAtpCompanies;
