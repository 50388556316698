import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

interface UseBilledDatePickerProps {
    formValues: {
        billed_date?: number;
    };
    handleFieldEvent: (values: any) => void;
}

export function normalizeToCalendarUTC(
    date: Date | number | string | any,
): dayjs.Dayjs {
    let dateStr: string = '';

    if (date instanceof Date) {
        dateStr = dayjs(date).format('YYYY-MM-DD');
    } else if (typeof date === 'number') {
        dateStr = dayjs
            .unix(date.toString().length === 10 ? date : date / 1000)
            .format('YYYY-MM-DD');
    } else if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
        dateStr = date;
    } else {
        dateStr = dayjs(date).format('YYYY-MM-DD');
    }

    return dayjs.utc(dateStr, 'YYYY-MM-DD', true);
}

function isInCurrentMonthAndFuture(date: dayjs.Dayjs): boolean {
    const nowUTC = dayjs.utc().startOf('day');
    return (
        date.year() === nowUTC.year() &&
        date.month() === nowUTC.month() &&
        date.isSameOrAfter(nowUTC)
    );
}

export const isDateEnabled = (date: number | Date | string) => {
    const normalizedDate = normalizeToCalendarUTC(date);
    return isInCurrentMonthAndFuture(normalizedDate);
};

export const validateBilledDate = (date: number | Date | string) => {
    const normalizedDate = normalizeToCalendarUTC(date);

    if (!isInCurrentMonthAndFuture(normalizedDate)) {
        return dayjs.utc(date).isBefore(dayjs.utc().startOf('day'))
            ? 'Select a future date.'
            : 'Date must be in the current month.';
    }
    return false;
};

export const useBilledDatePicker = ({
    formValues,
    handleFieldEvent,
}: UseBilledDatePickerProps) => {
    const [datePickerValue, setDatePickerValue] = useState(() => {
        return formValues.billed_date
            ? dayjs.unix(formValues.billed_date).format('YYYY-MM-DD')
            : dayjs().endOf('month').format('YYYY-MM-DD');
    });

    useEffect(() => {
        if (!formValues.billed_date) {
            const defaultDate = dayjs().endOf('month').format('YYYY-MM-DD');
            handleFieldEvent({
                billed_date: dayjs(defaultDate).unix(),
            });
        }
    }, []);

    useEffect(() => {
        const newDateValue = formValues.billed_date
            ? dayjs.unix(formValues.billed_date).format('YYYY-MM-DD')
            : dayjs().endOf('month').format('YYYY-MM-DD');
        setDatePickerValue(newDateValue);
    }, [formValues.billed_date]);

    const handleDateChange = (detail: { value: string | null }) => {
        const newValue = detail.value || '';
        setDatePickerValue(newValue);
        const isValidDate = dayjs(newValue, 'YYYY-MM-DD', true).isValid();

        if (isValidDate) {
            handleFieldEvent({
                billed_date: dayjs(newValue).unix(),
            });
        }
    };

    return {
        datePickerValue,
        handleDateChange,
    };
};
