import { ActivityData, DeliveryInstructor } from './activity';
import { ActivityStatus } from '../components/Activity/Common/Common';

export interface ActivityGroupRecord {
    pk?: string;
    name: string;
    active: boolean;
    record_type?: string;
    created_by?: string;
    created_timestamp?: number;
    modified_by?: string;
    modified_timestamp?: number;
    status: ActivityStatus;
    program_name: string;
    catalog_item_id: string;
    catalog_item_versioned_id: string;
    start_timestamp: number;
    end_timestamp: number;
}

export const ActivityGroupListColumnLabels: ReadonlyArray<
    keyof ActivityGroupRecord
> = [
    'name',
    'status',
    'active',
    'program_name',
    'modified_by',
    'start_timestamp',
    'end_timestamp',
    'created_by',
    'created_timestamp',
    'modified_timestamp',
    'catalog_item_id',
    'catalog_item_versioned_id',
    'record_type',
];

export type ActivityGroupMode = 'CREATE' | 'EDIT' | 'VIEW';

export type ActivityGroupChildActivity = Pick<
    ActivityData,
    | 'activity_name'
    | 'activity_type'
    | 'activity_audience'
    | 'activity_modality'
    | 'class_size'
    | 'course_catalog_item_id'
    | 'course_name'
    | 'course_days'
    | 'customer_support_manager'
    | 'customer_support_manager_email'
    | 'delivery_sessions'
    | 'delivery_timezone'
    | 'delivery_country'
    | 'delivery_state'
    | 'delivery_city'
    | 'delivery_region'
    | 'delivery_geo'
    | 'instructors'
    | 'lms_type'
    | 'operations_owner'
    | 'operations_owner_email'
    | 'pk'
    | 'program'
    | 'provider'
    | 'topic_name'
    | 'partner_initiative'
    | 'delivery_language'
    | 'scheduler'
    | 'scheduler_email'
> & {
    activity_group_name: string;
    activity_status: ActivityStatus;
    /** derived from the activity_name: `activity_group_name##delivery_name##option_number` */
    delivery_name?: string;
    /** derived from the activity_name: `activity_group_name##delivery_name##option_number` */
    option_number?: number;
};

export interface ActivityGroupData extends ActivityGroupRecord {
    is_loading: boolean;
    error?: {
        title: string;
        message: string;
        linkText: string;
    } | null;
    course_name: string;
    selected_timezone: string;
    child_activities: ActivityGroupChildActivity[];
    instructor_pool: DeliveryInstructor[];
    /** maps delivery names to a list of option activity names */
    delivery_map: {
        [name: string]: string[];
    };
}
