/**
 * Method to append api base url for the current domain
 * @return {string} Returns the api base url
 */
const getApiUrl = () => {
    const url = window.location.origin;
    if (url.startsWith('http://localhost')) {
        if (process.env.REACT_APP_LOCAL_API_URL) {
            return process.env.REACT_APP_LOCAL_API_URL;
        } else {
            // eslint-disable-next-line no-console
            console.error(
                'Add REACT_APP_LOCAL_API_URL=YOU_API_URL environment variable to .env.local and restart dev server.',
            );
        }
    }

    return url.replace('https://', 'https://api.');
};

export default getApiUrl;
