import { Button } from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import Section from '../../../../../../common/components/Section/Section';
import { ActivityStatus } from '../../../Common/Common';
import {
    useActivityDetailsFormOptions,
    useActivityGroup,
} from '../../hooks/hooks';
import { activityGroupDetailsColumnDefinition } from './ActivityGroupDetailsColumnDefinition';

const ActivityGroupDetails = () => {
    const activityGroup = useActivityGroup();
    const [{ courseOptions }, coursesLoading] = useActivityDetailsFormOptions();

    return (
        <Section
            header={{
                label: 'Activity details',
                buttons: (
                    <Link
                        to={`/activities/group/${activityGroup.name}/edit-activity-details`}
                    >
                        <Button
                            disabled={
                                activityGroup.status === ActivityStatus.Canceled
                            }
                            disabledReason="Cannot edit a canceled activity group."
                            data-testid="EditGroupButton"
                        >
                            Edit
                        </Button>
                    </Link>
                ),
            }}
            content={activityGroupDetailsColumnDefinition({
                coursesLoading,
                courseOptions,
                activityGroup,
            })}
        />
    );
};

export default ActivityGroupDetails;
