import { useEffect } from 'react';
import '@amzn/awsui-components-react/index.css';
import { AppLayout, Flashbar } from '@amzn/awsui-components-react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './App.scss';
import Header from '../../common/components/Header';
import Navigation from '../../common/components/Navigation';
import Routes from '../Routes';
import { useNotifications } from '../../common/context/grimsbyNotifications';
import {
    selectError,
    selectIsLoaded,
    selectStatus,
    selectUser,
} from '../../common/store/slices/userSlice';
import { RedirectionData } from '../../common/interfaces/redirection';
import Footer from '../../common/components/Footer';
import { useGrimsbyNav } from '../../common/context/grimsbyNavigation';
import Authenticator from '../../main/Authenticator/Authenticator';
import { checkUserDetails } from '../../common/utils/auth';
import Panel from '../../common/components/HelpPanel/HelpPanel';
import { HELP_PANELS_MAP } from '../../common/components/HelpPanel/helpPanelMap';
import { HelpPanelProps } from '../../common/components/HelpPanel/helpPanelTypes';
import { useHelpPanel } from '../../common/context/HelpPanelContext';
import { findMatchingPanel } from '../../common/utils/routeMatchUtils';

const App = () => {
    const userDetailsAreLoaded = useSelector(selectIsLoaded);
    const userDetails = useSelector(selectUser);

    const userDetailsError = useSelector(selectError);

    const userDetailsStatusCode = useSelector(selectStatus);
    const { notifications } = useNotifications();
    const { sideNavEnabled, isNavOpened } = useGrimsbyNav();
    const {
        state: { helpPanelTopic, toolsOpen },
        actions: { setHelpPanelTopic, setToolsOpen },
    } = useHelpPanel();

    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        const matchingPanel = findMatchingPanel(location.pathname);
        if (matchingPanel) {
            setHelpPanelTopic(matchingPanel.title);
        } else {
            setHelpPanelTopic(null);
        }
        // eslint-disable-next-line no-restricted-globals
    }, [location.pathname]);

    const redirectToErrorPage = (data: RedirectionData): JSX.Element | null => {
        if (data.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: data.path || '/error',
                        state: {
                            children: data.message,
                        },
                    }}
                />
            );
        }
        return null;
    };

    const redirection = checkUserDetails(
        userDetailsAreLoaded,
        userDetailsError,
        userDetailsStatusCode,
        userDetails,
    );

    return (
        <Router>
            <Authenticator globals={window}>
                <div className="awsui" data-testid="App">
                    <Header />
                    <AppLayout
                        data-testid="MainContent"
                        toolsHide={!helpPanelTopic}
                        toolsOpen={toolsOpen}
                        tools={
                            <Panel
                                {...(HELP_PANELS_MAP[
                                    helpPanelTopic
                                ] as HelpPanelProps)}
                            />
                        }
                        className="fullscreen"
                        content={<Routes />}
                        headerSelector=".grimsby-header"
                        footerSelector=".grimsby-footer"
                        navigation={userDetailsAreLoaded && <Navigation />}
                        navigationHide={!sideNavEnabled}
                        navigationOpen={isNavOpened}
                        notifications={<Flashbar items={notifications} />}
                        onToolsChange={({ detail }) =>
                            setToolsOpen(detail.open)
                        }
                    />
                    <Footer />
                </div>
            </Authenticator>
            {redirectToErrorPage(redirection)}
        </Router>
    );
};

export default App;
