import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './main/App';
import store from './main/store';
import { NotificationsProvider } from './common/context/grimsbyNotifications';
import { GrimsbyNavProvider } from './common/context/grimsbyNavigation';
import GrimsbyGlobalErrorBoundary from './common/components/ErrorBoundary/GrimsbyGlobalErrorBoundary';
import { AuthenticationProvider } from './common/context/AuthenticationProvider';
import { AuthorizationProvider } from './common/context/AuthorizationProvider';
import { HelpPanelProvider } from './common/context/HelpPanelContext';

ReactDOM.render(
    <Provider store={store}>
        <AuthenticationProvider>
            <AuthorizationProvider>
                <NotificationsProvider>
                    <GrimsbyNavProvider>
                        <GrimsbyGlobalErrorBoundary showFallback={true}>
                            <HelpPanelProvider>
                                <App />
                            </HelpPanelProvider>
                        </GrimsbyGlobalErrorBoundary>
                    </GrimsbyNavProvider>
                </NotificationsProvider>
            </AuthorizationProvider>
        </AuthenticationProvider>
    </Provider>,
    document.getElementById('root'),
);
