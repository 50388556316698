import {
    Alert,
    Box,
    SpaceBetween,
    TextContent,
    Link,
} from '@amzn/awsui-components-react-v3';

interface NotFoundHandlerProps {
    isNotFound: boolean;
    redirectPath: string;
    title: string;
    message: string;
    linkText: string;
}

export const use404Handler = ({
    isNotFound,
    redirectPath,
    title,
    message,
    linkText,
}: NotFoundHandlerProps) => {
    if (!isNotFound) {
        return null;
    }

    return (
        <Alert data-testid="404Alert" type="error">
            <Box variant="h2">{title}</Box>
            <SpaceBetween size="xxxs" direction="horizontal">
                <TextContent>{message}</TextContent>
                <Link href={redirectPath}>{linkText}</Link>
            </SpaceBetween>
        </Alert>
    );
};
