import { FormField, Select } from '@amzn/awsui-components-react';
import {
    Button,
    ColumnLayout,
    Header,
    Link,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import {
    getGeographiesList,
    selectAllActiveGeographies,
    selectIsLoading as selectIsGeographyListLoading,
    selectIsLoaded as selectIsGeographyListLoaded,
} from '../../../common/store/slices/geographiesSlice';
import useFormValidation from '../../../common/utils/formValidation';
import { getOptionsAndLookupForSelectInput } from '../../../imt/components/Instructor/FormSections/FormSections.common';
import schedulemanagementApi from '../../api/scheduleManagementApi';
import { GeographyItemData } from '../../../common/interfaces/businessDataItem/geographyItem';
import { useHelpPanel } from '../../../common/context/HelpPanelContext';
import { EXPORT_INVOICE_PANEL_CONTENT } from '../../../common/components/HelpPanel/HelpPanelContent';
import { InfoLink } from '../../../common/components/InfoLink/InfoLink';

export const geosErrorText = 'An error occurred while loading regions';

interface ExportInvoiceFormData {
    region?: string;
}

const ExportInvoiceData = () => {
    const isGeoListLoading = useSelector(selectIsGeographyListLoading);
    const geographyList = useSelector(selectAllActiveGeographies);
    const isGeographyListLoading = useSelector(selectIsGeographyListLoading);
    const isGeographyListLoaded = useSelector(selectIsGeographyListLoaded);
    const dispatch = useDispatch();
    const [selectedGeo, setSelectedGeo] = useState<string | undefined>(
        undefined,
    );
    const {
        actions: { makeHelpPanelHandler },
    } = useHelpPanel();
    const { errors, validateForm } = useFormValidation<ExportInvoiceFormData>();
    const { addNotification } = useNotifications();
    const [isGeneratingInvoice, setIsGeneratingInvoice] =
        useState<boolean>(false);
    useEffect(() => {
        (
            [
                [
                    !isGeographyListLoading && !isGeographyListLoaded,
                    getGeographiesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    const { valueLookup: geoLookup, valueOptions: geoOptions } =
        getOptionsAndLookupForSelectInput<GeographyItemData>(
            geographyList,
            (geo: GeographyItemData) => ({
                label: geo.geo,
                id: geo.pk as string,
            }),
        );

    const handleExportInvoice = async (e: Event) => {
        const isValid = !validateForm(
            { region: selectedGeo },
            { required: ['region'] },
        );

        if (!isValid || !selectedGeo) {
            return;
        }

        try {
            setIsGeneratingInvoice(true);
            const invoiceResponse =
                await schedulemanagementApi.getClipperInvoiceByGeo({
                    geo: selectedGeo,
                });

            if (!invoiceResponse?.result || !invoiceResponse?.result?.url) {
                setIsGeneratingInvoice(false);
                addNotification({
                    id: `create-activity-${Date.now()}`,
                    type: 'error',
                    content: 'An error occurred while generating invoice.',
                });
                return;
            }

            const downloadLink = document.createElement('a');
            downloadLink.href = invoiceResponse.result.url;
            downloadLink.target = `_blank`;
            downloadLink.click();

            setIsGeneratingInvoice(false);
        } catch (e) {
            setIsGeneratingInvoice(false);
            addNotification({
                id: `export-invoice`,
                type: 'error',
                content: 'An error occurred while generating invoice.',
            });
        }
    };

    return (
        <div data-testid="ActivityDetailsPage">
            <div className="awsui-util-action-stripe-large">
                <div className="awsui-util-action-stripe-title awsui-util-mb-m">
                    <Header
                        info={
                            <InfoLink
                                onFollow={makeHelpPanelHandler(
                                    EXPORT_INVOICE_PANEL_CONTENT.title,
                                )}
                            />
                        }
                        variant="h1"
                    >
                        Export invoice data
                    </Header>
                </div>
            </div>
            <div data-testid="ExportInvoiceDataSection">
                <FormField
                    errorText={errors?.region}
                    label="Geography"
                    description="Export invoice data for a selected geography."
                >
                    <ColumnLayout columns={2}>
                        <Select
                            data-testid={`GetInvoiceSelectGeo`}
                            options={geoOptions}
                            placeholder={'Select geo'}
                            selectedOption={
                                selectedGeo ? geoLookup[selectedGeo] : null
                            }
                            selectedLabel="Selected"
                            onChange={(event) =>
                                setSelectedGeo(
                                    event.detail.selectedOption.label,
                                )
                            }
                            loadingText="Loading geos"
                            errorText={geosErrorText}
                            recoveryText="Retry"
                            empty="No geos found"
                            disabled={isGeoListLoading}
                        />
                        <Button
                            onClick={handleExportInvoice}
                            variant="primary"
                            data-testid="ExportInvoiceButton"
                            disabled={isGeneratingInvoice}
                        >
                            Export
                        </Button>
                    </ColumnLayout>
                </FormField>
            </div>
        </div>
    );
};

export default ExportInvoiceData;
