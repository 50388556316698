import React from 'react';
import { Link, LinkProps } from '@amzn/awsui-components-react-v3';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

interface InfoLinkProps {
    onFollow: LinkProps['onFollow'];
}

export const InfoLink = (props: InfoLinkProps) => {
    const canShowHelpPanel = useFeatureFlag('enable-help-panel');

    if (!canShowHelpPanel) {
        return null;
    }

    return (
        <Link variant="info" {...props}>
            Info
        </Link>
    );
};
