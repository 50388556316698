import React, { useCallback, useState, useEffect } from 'react';
import { ActivityData, Invoice } from '../../../../interfaces/activity';
import {
    getSelectedActivity,
    selectIsLoading,
    selectSelectedActivity,
    updateSelectedActivity,
} from '../../../../store/slices/selectedActivitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';
import {
    Alert,
    Box,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { useNotifications } from '../../../../../common/context/grimsbyNotifications';

import { InvoiceFormSection } from './InvoiceFormSection';
import useFormValidation from '../../../../../common/utils/formValidation';
import {
    saveInvoiceValidationConfig,
    saveInvoiceValidationNonDraftConfig,
} from '../../../../services/activity-service';
import { AudienceType } from '../../Common/Common';
import { BillingStatus } from './InvoicesTab';
import { validateBilledDate } from './BilledDatePicker';
import { checkFeature } from '../../../../../common/utils/featureFlag';
import { selectFeatures } from '../../../../../common/store/slices/featureSlice';

const InvoiceEditForm = () => {
    const isLoading = useSelector(selectIsLoading);
    const match = useRouteMatch<{ id: string; invoiceId: any }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const activity: ActivityData | undefined = useSelector(
        selectSelectedActivity,
    )?.activity;
    const featureFlags = useSelector(selectFeatures);
    const isCanadaBillImmediatelyEnabled = checkFeature(
        '',
        { featureName: 'nmbs-billing-immediately-canada' },
        featureFlags?.features,
    );
    const { addNotification, removeAllNotifications } = useNotifications();
    const initialFormState = {
        billing_status: 'Draft',
        currency: 'USD',
        do_not_group: false,
        payment_terms: '30 NET',
        is_reseller: false,
        line_items: [
            {
                item_type:
                    activity?.activity_audience === 'Public'
                        ? 'Public ILT class'
                        : 'Private ILT class',
                item_amount: 0,
            },
        ],
        prepay: false,
    } as Invoice;

    const [formValues, setFormValues] = useState<Invoice>(initialFormState);

    const { errors, setErrors, validateForm } = useFormValidation<Invoice>();

    const [showWarningModal, setShowWarningModal] = useState(false);

    const handleFieldEvent = useCallback((changes: Partial<Invoice>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    }, []);

    useEffect(() => {
        dispatch(getSelectedActivity(match.params.id));
    }, [dispatch, match.params.id]);

    useEffect(() => {
        removeAllNotifications();
    }, [removeAllNotifications]);

    useEffect(() => {
        if (activity && activity.billing_invoices) {
            setFormValues(activity.billing_invoices[match.params.invoiceId]);
        }
    }, [match.params.invoiceId, activity]);

    const isBilledDateEnabled =
        checkFeature(
            '',
            { featureName: 'billed-date' },
            featureFlags?.features,
        ) &&
        (formValues.billing_status === BillingStatus.ToBeBilled ||
            formValues.billing_status === 'To be billed - manually');

    const sumLineItems = () => {
        return formValues.line_items.reduce((sum: number, lineItem) => {
            return sum + lineItem.item_amount;
        }, 0);
    };

    const validBillImmediatelyCountries = isCanadaBillImmediatelyEnabled
        ? ['United States', 'Canada']
        : ['United States'];

    const customValidations = () => {
        let customErrors = {};
        if (isBilledDateEnabled) {
            const billedDateError = validateBilledDate(formValues.billed_date);
            if (billedDateError) {
                customErrors = {
                    ...customErrors,
                    billed_date: billedDateError,
                };
            }
        }

        if (`${formValues?.bill_to_address_1}`.length > 180) {
            customErrors = {
                ...customErrors,
                bill_to_address_1:
                    'Address 1 has a maximum length of 180 characters.',
            };
        }
        if (`${formValues?.bill_to_address_2}`.length > 60) {
            customErrors = {
                ...customErrors,
                bill_to_address_2:
                    'Address 2 has a maximum length of 60 characters.',
            };
        }

        if (formValues.billing_status === BillingStatus.BillImmediately) {
            let billing_status_error: string;

            if (activity.activity_audience !== AudienceType.CommercialPrivate) {
                billing_status_error =
                    'Bill Immediately is only supported for private commercial activities ';
            } else if (
                activity.billing_invoices[0].billing_status ===
                BillingStatus.BillImmediately
            ) {
                billing_status_error =
                    'Billing Status already set to Bill Immediately so unable to edit activity. If you want to edit activity then put billing status to draft and edit activity. ';
            } else if (
                !validBillImmediatelyCountries.includes(
                    activity.delivery_country,
                )
            ) {
                billing_status_error = `Bill Immediately only supported for ${validBillImmediatelyCountries.join(
                    ' and ',
                )} SOR.`;
            }

            if (billing_status_error) {
                customErrors = {
                    ...customErrors,
                    billing_status: billing_status_error,
                };
            }
        }

        if (
            formValues.billing_status === 'To be billed' &&
            sumLineItems() <= 0
        ) {
            customErrors = {
                ...customErrors,
                billing_status:
                    "Can't be status 'To be billed' if line item(s) total is 0 or less.",
            };
        }

        if (formValues?.customer_invoice_notes?.length > 500) {
            customErrors = {
                ...customErrors,
                customer_invoice_notes:
                    "Customer notes can't be more than 500 characters.",
            };
        }

        if (formValues?.prepay === true && !formValues.request_for_funds_id) {
            customErrors = {
                ...customErrors,
                request_for_funds_id:
                    'RFF ID can not be blank when Prepay is Yes.',
            };
        }

        if (Object.keys(customErrors).length > 0) {
            setErrors({ ...errors, ...customErrors });
            return true;
        }

        return false;
    };

    const handleSaveInvoice = async () => {
        if (formValues.billing_status === BillingStatus.BillImmediately) {
            setShowWarningModal(true);
        } else {
            await saveInvoice();
        }
    };

    const handleConfirmAndSave = async () => {
        setShowWarningModal(false);
        await saveInvoice();
    };

    const saveInvoice = async () => {
        if (!!activity) {
            const invalid =
                validateForm(
                    formValues,
                    formValues.billing_status === 'Draft'
                        ? saveInvoiceValidationConfig
                        : saveInvoiceValidationNonDraftConfig(
                              formValues.is_reseller,
                              isBilledDateEnabled,
                          ),
                ) || customValidations();

            if (!invalid) {
                let updatedInvoices: Array<Invoice> = [];
                let totalAmount = 0;

                for (let lineItem of formValues.line_items) {
                    totalAmount += lineItem.item_amount;
                }

                updatedInvoices = [...activity.billing_invoices];
                updatedInvoices[match.params.invoiceId] = {
                    ...formValues,
                    customer_name: formValues.customer_name.replace(
                        ` (${formValues.sfdc_opportunity_id})`,
                        '',
                    ),
                    legal_entity_name: formValues.legal_entity_name.replace(
                        ` (${formValues.sfdc_opportunity_id})`,
                        '',
                    ),
                };
                updatedInvoices[match.params.invoiceId].billed_amount =
                    totalAmount;

                const isSuccessful = await dispatch<any>(
                    updateSelectedActivity(match.params.id, {
                        ...activity,
                        billing_invoices: updatedInvoices,
                    }),
                );

                addNotification({
                    id: `edit-activity-invoice-${Date.now()}`,
                    ...(isSuccessful
                        ? {
                              type: 'success',
                              content: 'The invoice has been saved.',
                          }
                        : {
                              type: 'error',
                              content:
                                  'An error occurred while saving the invoice.',
                          }),
                });

                if (isSuccessful) {
                    history.push({
                        pathname: `/activities/${match.params.id}`,
                        search: 'tabId=invoices',
                    });
                }
            }
        }
        return;
    };

    return (
        <section data-testid="InvoiceEditForm">
            <Form
                header="Invoice details"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-invoice-cancel"
                                data-testid="InvoiceEditFormCancel"
                                onClick={() => {
                                    history.push({
                                        pathname: `/activities/${match.params.id}`,
                                        search: 'tabId=invoices',
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-invoice-save"
                                data-testid="InvoiceEditFormSaveButton"
                                loading={isLoading}
                                onClick={() => {
                                    handleSaveInvoice();
                                }}
                            >
                                {`${isLoading ? 'Saving' : 'Save'}`}
                            </Button>
                        </div>
                    </div>
                }
            >
                <InvoiceFormSection
                    formValues={formValues}
                    errors={errors}
                    handleFieldEvent={handleFieldEvent}
                    activity={activity}
                    isBilledDateEnabled={isBilledDateEnabled}
                />
            </Form>

            <Modal
                data-testid="BillImmediatelyWarningModal"
                onDismiss={() => setShowWarningModal(false)}
                visible={showWarningModal}
                closeAriaLabel="Close modal"
                size="medium"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                data-testid="CancelBillImmediately"
                                onClick={() => setShowWarningModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid="ConfirmBillImmediately"
                                variant="primary"
                                onClick={handleConfirmAndSave}
                            >
                                Confirm and Save
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Warning: Bill Immediately"
            >
                <Alert type="warning">
                    Once saved with "Bill Immediately" status, this invoice
                    cannot be changed. Are you sure you want to proceed ?
                </Alert>
            </Modal>
        </section>
    );
};

export default InvoiceEditForm;
