import { Link } from 'react-router-dom';
import {
    ActivityGroupListColumnLabels,
    ActivityGroupRecord,
} from '../../../../interfaces/activityGroup';
import { TableProps } from '@amzn/awsui-components-react-v3';
import { formatGrimsbyDate } from '../../Common/Common';

export const formatHeader = (str: string): string => {
    const words = str
        .split('_')
        .filter(Boolean)
        .map((word) => word.toLowerCase());

    return words
        .map((word, index) => {
            if (index === 0) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            } else {
                return word;
            }
        })
        .join(' ');
};

const getColumnDefinition = (
    propertyKey: keyof ActivityGroupRecord,
): TableProps.ColumnDefinition<ActivityGroupRecord> => {
    return {
        id: propertyKey,
        header: formatHeader(propertyKey),
        cell: (item: ActivityGroupRecord) => {
            const value = item[propertyKey];
            if (propertyKey === 'name') {
                return (
                    <Link to={`/activities/group/${item.name}/`}>{value}</Link>
                );
            }

            if (propertyKey.includes('timestamp')) {
                return formatGrimsbyDate(value as number);
            }
            return `${value}`;
        },
        sortingField: propertyKey,
    };
};

export const columnDefinitions: ReadonlyArray<
    TableProps.ColumnDefinition<ActivityGroupRecord>
> = ActivityGroupListColumnLabels.map((label: string) =>
    getColumnDefinition(label as keyof ActivityGroupRecord),
);
