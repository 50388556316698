import {
    Container,
    Header,
    KeyValuePairs,
    Table,
    Link as CloudScapeLink,
    FormField,
    Select,
    Button,
} from '@amzn/awsui-components-react-v3';
import { useActivityGroupDeliveryWeekOptions } from '../../hooks/useActivityGroupDeliveryWeekOptions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Link } from 'react-router-dom';
import { ActivityStatus } from '../../../Common/Common';
import { useActivityGroup } from '../../hooks/hooks';
import { sessionTableColumns } from './SessionTableColumns';
import { extractDeliveryName } from '../../utils/extractDeliveryName';
import { getActivityDetailsItems } from './getActivityDetailsItems';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const ActivityGroupDeliveryDetails = () => {
    const activityGroup = useActivityGroup();
    const {
        status,
        child_activities: childActivities,
        name,
    } = activityGroup || {};

    const { weekOptionsForDates, selectedWeek, handleWeekChange } =
        useActivityGroupDeliveryWeekOptions({
            start: activityGroup.start_timestamp,
            end: activityGroup.end_timestamp,
            timezone: activityGroup.selected_timezone,
            includeAllOption: false,
        });

    const getFilteredSessions = () => {
        if (!childActivities || childActivities.length === 0) {
            return [];
        }

        const weekStart = dayjs(selectedWeek.value);
        const weekEnd = weekStart.add(1, 'week');

        return childActivities
            .flatMap((activity) => {
                return (activity.delivery_sessions || []).map((session) => ({
                    ...session,
                    timezone: activity.delivery_timezone,
                    activityName: (
                        <span>
                            <CloudScapeLink
                                external
                                href={`/activities/${activity.pk}`}
                            >
                                {extractDeliveryName(activity.activity_name)}
                            </CloudScapeLink>
                        </span>
                    ),
                }));
            })
            .filter((session) => {
                const sessionDate = dayjs.unix(session.start_timestamp);
                return (
                    sessionDate.isAfter(weekStart) &&
                    sessionDate.isBefore(weekEnd)
                );
            });
    };

    if (!activityGroup || !childActivities || childActivities.length === 0) {
        return null;
    }

    const filteredSessions = getFilteredSessions();

    return (
        <div>
            <Container
                header={
                    <Header
                        actions={
                            <Link
                                to={`/activities/group/${name}/edit-delivery-details`}
                            >
                                <Button
                                    disabled={
                                        status === ActivityStatus.Canceled
                                    }
                                    disabledReason="Cannot edit a canceled activity group."
                                    data-testid="EditButtonActivityGroup"
                                >
                                    Edit
                                </Button>
                            </Link>
                        }
                        variant="h2"
                    >
                        Delivery details
                    </Header>
                }
                variant="stacked"
            >
                <KeyValuePairs
                    columns={3}
                    items={getActivityDetailsItems(childActivities[0])}
                />
            </Container>

            <FormField stretch={true}>
                <Select
                    options={weekOptionsForDates}
                    selectedOption={selectedWeek}
                    onChange={({ detail }) =>
                        handleWeekChange(detail.selectedOption)
                    }
                />
            </FormField>

            <Table
                resizableColumns
                sortingDisabled
                variant="stacked"
                header={
                    <Header
                        variant="h2"
                        counter={`(${filteredSessions.length})`}
                    >
                        Sessions
                    </Header>
                }
                empty={
                    <div>
                        <p>No sessions found for the selected week.</p>
                    </div>
                }
                columnDefinitions={sessionTableColumns}
                items={filteredSessions}
            />
        </div>
    );
};

export default ActivityGroupDeliveryDetails;
