import { TextContent } from '@amzn/awsui-components-react-v3';
import { HelpPanelConfig } from './helpPanelTypes';

export const EXPORT_INVOICE_PANEL_CONTENT: HelpPanelConfig = {
    route: '/activities/clipper-export',
    title: 'Export Invoice',
    content: (
        <TextContent>
            <p>
                Access and download billing information for your activities.
                Exporting invoicing data allows for precise billing and
                adaptable billing scenarios. This tool is commonly used to
                manually upload invoices to Clipper, handle billing in
                unsupported countries, and review billing data before month-end.
            </p>
            <p>
                Invoice data include a CSV file of activities that ended in the
                current month and the past 2 months. To get started, select a
                target geography region and submit the export.{' '}
            </p>
        </TextContent>
    ),
    links: null,
};

export const ACTIVITY_HELP_PANEL_CONTENT = {
    route: '/activities',
    title: 'Activities',
    content: (
        <TextContent>
            <p>
                Grimsby activities are scheduled instructor-led training
                deliveries that training administrators can create and manage in
                the Schedule Management console. You can use the activities page
                to import, schedule, monitor, and maintain training deliveries
                for supported organizations, even across multiple departments or
                locations.
            </p>
            <p>
                Begin training scheduling by creating activities; use single
                entry or bulk Excel upload (up to 100 activities) for public
                classes outside Grimsby and SFDC. You can create unlimited
                activities based on your customer's needs. All activities,
                regardless of creation method, are centrally managed and visible
                to administrators with the appropriate permissions.
            </p>
            <p>
                Note: For activities flowing through Salesforce, please use the
                SFDC queue rather than bulk import to ensure proper system
                synchronization.
            </p>
        </TextContent>
    ),
    links: [
        {
            text: 'User guide',
            href: 'https://tiny.amazon.com/vwctffsu/wamazbinprevAWS_ProdILTFlas',
        },
    ],
};

export const INSTRUCTOR_HELP_PANEL_CONTENT = {
    route: '/activities/instructors',
    title: 'Instructor Schedule',
    content: (
        <TextContent>
            <p>
                A comprehensive calendar view of all instructor-related
                availability. Instructor schedule is essential for efficiently
                managing instructor resources and planning training schedules
                for instructors. You can use this view to track both podium and
                non-podium time commitments for all instructors in a single,
                consolidated display. The schedule displays everything that
                appears on an instructor's calendar, providing complete
                visibility into their time allocation. You can apply various
                filters to focus on specific instructor-related criteria and
                manage scheduling more effectively. When using filters in this
                view, they are specifically tied to instructor attributes rather
                than training activity details.
            </p>
        </TextContent>
    ),
    links: null,
};

export const SALESFORCE_QUEUE_HELP_PANEL_CONTENT = {
    route: '/activities/sfdc-queue',
    title: 'Salesforce Queue',
    content: (
        <TextContent>
            <p>
                The Salesforce (SFDC) queue allows for seamless creation of
                training activities through a structured workflow between SFDC
                and Grimsby, ensuring proper synchronization of training data
                across both systems. Approved SFDC scheduling requests initiate
                the transmission of new activity data to Grimsby. Each product
                line from the Salesforce scheduling request creates a separate
                line item in the SFDC queue, where confirmation is required
                before appearing in the activities console as an activity.
            </p>
        </TextContent>
    ),
    links: null,
};

export const CLASSROOM_PANEL_CONTENT: HelpPanelConfig = {
    route: '/activities',
    title: 'Classrooms details',
    content: (
        <TextContent>
            <p>
                Classrooms (a.k.a Builder Labs for classes) allows instructors
                to manage all aspects of their classroom experience in one
                centralized location. This includes managing AWS labs
                environments for their learners, tracking attendance, sharing
                video conferencing details and providing course materials to
                learners.
            </p>
            <p>
                Create a Classroom here for the instructor to view and access
                their class in the “Upcoming & Active Class” Classroom dashboard
                on the day of class. Before creating a classroom, ensure your
                activity meets the required conditions.
                <ul>
                    <li>Active status</li>
                    <li>Class size greater than 0</li>
                    <li>Commercial program type</li>
                    <li>
                        Valid vILT link and meeting ID for virtual activities
                    </li>
                    <li>Instructor(s) assigned</li>
                </ul>
            </p>
            <p>
                NOTE: If you encounter an error in the Classroom creation
                workflow, verify the above conditions and attempt the action a
                second time. If this does not work, report an issue.
            </p>
        </TextContent>
    ),
    links: [
        {
            text: 'Report an Issue',
            href: 'https://t.corp.amazon.com/create/templates/e7823b9c-1683-42e7-bca9-10b60e28eeee',
        },
    ],
};
