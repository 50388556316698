import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../../../main/store';
import { useActivityGroup } from './useActivityGroup';
import { getCatalogCoursesByMultipleGroupIds } from '../../../../../common/api/helpers';
import businessDataApi from '../../../../../common/api/businessDataApi';

type CourseOption = {
    courseName: string;
    catalogVersionedId: string;
    catalogId: string;
};

type ActivityGroupFormOptions = {
    activityStatuses: string[];
    courseOptions: CourseOption[];
    programs: string[];
    timezones: string[];
};

export const useActivityDetailsFormOptions = (): [
    ActivityGroupFormOptions,
    boolean,
    boolean,
] => {
    const userPrograms = useSelector(
        (state: GlobalState) => state.user.user.profile.programs,
    );
    const [formOptionsLoading, setFormOptionsLoading] = useState(false);
    const [coursesLoading, setCoursesLoading] = useState(false);

    const [formOptions, setFormOptions] = useState<ActivityGroupFormOptions>({
        activityStatuses: [],
        courseOptions: [],
        programs: [],
        timezones: [],
    });

    const activityGroup = useActivityGroup();

    useEffect(() => {
        setFormOptionsLoading(true);

        const courseOptions: CourseOption[] = [];

        const getActivityStatuses = () =>
            businessDataApi
                .getActivityStatuses({ active: true })
                .then(({ result }) =>
                    result.ACTIVITY_STATUS.map(
                        ({ activity_status }) => activity_status,
                    ),
                );
        const getPrograms = () =>
            businessDataApi
                .getProgramTypes({ active: true })
                .then(({ result }) =>
                    result.PROGRAM_TYPE.filter(
                        (program) =>
                            userPrograms.includes(program.program_type) &&
                            Array.isArray(program.catalog_group_ids) &&
                            program.catalog_group_ids.length,
                    ),
                );
        const getTimezones = () =>
            businessDataApi
                .getLocations()
                .then(({ result }) =>
                    result.CITY.map(({ city_timezone }) => city_timezone),
                );

        Promise.all([
            getActivityStatuses(),
            getPrograms(),
            getTimezones(),
        ]).then(async ([activityStatuses, programs, timezones]) => {
            if (activityGroup.program_name) {
                const selectedProgram = programs.find(
                    (program) =>
                        program.program_type === activityGroup.program_name,
                );
                if (selectedProgram.catalog_group_ids?.length) {
                    setCoursesLoading(true);

                    const catalogCourses =
                        await getCatalogCoursesByMultipleGroupIds(
                            selectedProgram.catalog_group_ids,
                        );
                    catalogCourses.forEach((course) =>
                        courseOptions.push({
                            courseName: course.name,
                            catalogVersionedId: course.versionedId,
                            catalogId: course.id,
                        }),
                    );

                    setCoursesLoading(false);
                }
            }

            setFormOptions({
                activityStatuses,
                courseOptions,
                programs: programs.map(({ program_type }) => program_type),
                timezones: timezones
                    .reduce((acc, timezone) => {
                        return [
                            ...acc.filter((tz) => tz !== timezone),
                            timezone,
                        ];
                    }, [])
                    .sort(),
            });

            setFormOptionsLoading(false);
        });
    }, [activityGroup.program_name]);

    return [formOptions, formOptionsLoading, coursesLoading];
};
