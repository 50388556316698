import {
    FormField,
    Select,
    SelectProps,
} from '@amzn/awsui-components-react-v3';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    selectFeatures,
    selectIsLoaded as selectIsFeaturesLoaded,
} from '../../../../../common/store/slices/featureSlice';
import { checkFeature } from '../../../../../common/utils/featureFlag';
import {
    ActivityLMSType,
    ActivityModality,
    ActivityViltType,
} from '../../Common/Common';
import { ActivityData } from '../../../../interfaces/activity';

export const LMS_TYPE_DESCRIPTION = 'The LMS used for the class.';

export type LMSTypeValues =
    | ActivityLMSType.Kiku
    | ActivityLMSType.MyClass
    | ActivityLMSType.CustomerLMS
    | ActivityLMSType.ACI;

interface LMSTypeOption extends SelectProps.Option {
    readonly label: LMSTypeValues;
    readonly value: string;
}

const lmsTypeOptions: Array<LMSTypeOption> = [
    {
        label: ActivityLMSType.Kiku,
        value: ActivityLMSType.Kiku,
    },
    {
        label: ActivityLMSType.MyClass,
        value: ActivityLMSType.MyClass,
    },
    {
        label: ActivityLMSType.CustomerLMS,
        value: ActivityLMSType.CustomerLMS,
    },
    {
        label: ActivityLMSType.ACI,
        value: ActivityLMSType.ACI,
    },
];

const lmsTypeOptionsLookup = lmsTypeOptions.reduce(
    (acc, opt) => {
        acc[opt.label] = opt;
        return acc;
    },
    {} as {
        [key in LMSTypeValues]: LMSTypeOption;
    },
);

interface SelectLMSTypeProps {
    handleFieldEvent: (values: Partial<ActivityData>) => void;
    formValues: {
        activity_modality: string;
        lms_type: string;
        v_ilt_type?: string | null;
        classrooms_arn?: string | null;
        classrooms_status?: string | null;
    };
    errors: {
        activity_modality: string;
        lms_type: string;
        v_ilt_type: string;
        classrooms_arn?: String;
        classrooms_status?: String;
    };
    // TODO: Clean up feature flag: https://sim.amazon.com/issues/V1584942032
    isClassroomsComponentsEnabled?: boolean;
}

const SelectLMSType = ({
    handleFieldEvent,
    formValues,
    errors,
    // TODO: Clean up feature flag: https://sim.amazon.com/issues/V1584942032
    isClassroomsComponentsEnabled,
}: SelectLMSTypeProps) => {
    const featureFlags = useSelector(selectFeatures);
    const featuresLoaded = useSelector(selectIsFeaturesLoaded);

    const nectoForCustomerLmsFeatureFlag = useMemo(() => {
        return (
            featuresLoaded &&
            checkFeature(
                '',
                { featureName: 'necto-for-customer-lms' },
                featureFlags?.features,
            )
        );
    }, [featuresLoaded, featureFlags]);

    const isVILT = formValues.activity_modality === ActivityModality.vILT;
    const isHybrid = formValues.activity_modality === ActivityModality.Hybrid;
    const isViltOrHybrid = isVILT || isHybrid;

    // TODO: Clean up feature flag: https://sim.amazon.com/issues/V1584942032
    const disableLMSTypeEdit = (
        classrooms_arn: string,
        classrooms_status: string,
    ): boolean => {
        if (isClassroomsComponentsEnabled) {
            return !!classrooms_arn && !!classrooms_status;
        }
        return false;
    };

    useEffect(() => {
        handleViltTypeChange(formValues.lms_type);
    }, [formValues.lms_type]);

    const handleViltTypeChange = (lmsType) => {
        if (lmsType !== formValues.lms_type) {
            let v_ilt_type = formValues.v_ilt_type || '';
            // select vilt type if only one option available
            switch (lmsType) {
                case ActivityLMSType.MyClass:
                    v_ilt_type = ActivityViltType.WebEx;
                    break;
                case ActivityLMSType.Kiku:
                    v_ilt_type = ActivityViltType.WebEx;
                    break;
                case ActivityLMSType.CustomerLMS:
                    if (!nectoForCustomerLmsFeatureFlag) {
                        v_ilt_type = ActivityViltType.WebEx;
                    }
                    break;
                default:
                    break;
            }
            handleFieldEvent({
                v_ilt_type,
            });
        }
    };
    return (
        <FormField
            label={<span>LMS type</span>}
            errorText={errors?.lms_type}
            data-testid="formField-EditLMSType"
        >
            <Select
                placeholder="Select LMS type"
                options={lmsTypeOptions}
                selectedOption={
                    formValues.lms_type
                        ? lmsTypeOptionsLookup[
                              formValues.lms_type as LMSTypeValues
                          ]
                        : null
                }
                onChange={(e) => {
                    handleFieldEvent({
                        lms_type: e.detail.selectedOption.label,
                    });
                    isViltOrHybrid &&
                        handleViltTypeChange(e.detail.selectedOption.label);
                }}
                data-testid="EditLMSType"
                disabled={disableLMSTypeEdit(
                    formValues.classrooms_arn,
                    formValues.classrooms_status,
                )}
            />
        </FormField>
    );
};

export default SelectLMSType;
