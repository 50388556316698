import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { StatusIndicator } from '@amzn/awsui-components-react-v3';
import {
    fetchActivityGroupByName,
    activityGroupSlice,
} from '../../../../store/slices/activityGroupSlice';
import { useActivityGroup } from '../hooks/hooks';
import { Mode } from '../../../../../common/constants/navigation';
import { ActivityStatus } from '../../Common/Common';
import ActivityGroupEditDetails from '../components/Edit/ActivityGroupEditDetails';
import ActivityGroupEditDeliveryForm from '../components/Edit/ActivityGroupEditDeliveryForm';
import { use404Handler } from '../hooks/use404Handler';
import { ACTIVITY_PATH } from '../../../../constants/path';

interface ActivityGroupEditProps {
    mode?: Mode;
}

export const ActivityGroupEdit = ({ mode }: ActivityGroupEditProps) => {
    const dispatch = useDispatch();
    const { name } = useParams<{ name: string }>();

    const activityGroup = useActivityGroup();
    const { error, is_loading } = activityGroup;

    // Store the original status when the component mounts
    const [originalStatus, setOriginalStatus] = useState<ActivityStatus | null>(
        null,
    );

    useEffect(() => {
        if (name) {
            dispatch(activityGroupSlice.actions.setLoading(true));
            dispatch(fetchActivityGroupByName(name));
        }

        return () => {
            dispatch(activityGroupSlice.actions.clearActivityGroup());
        };
    }, [dispatch, name]);

    // Set the original status when activity group data is loaded
    useEffect(() => {
        if (activityGroup.status && !originalStatus) {
            setOriginalStatus(activityGroup.status);
        }
    }, [activityGroup.status]);

    if (activityGroup.is_loading) {
        <StatusIndicator type="loading">
            Loading Activity Group...
        </StatusIndicator>;
    }

    const notFoundAlert = use404Handler({
        isNotFound: !is_loading && !!error,
        redirectPath: ACTIVITY_PATH.GROUP_LIST,
        title: error && error.title,
        message: error && error.message,
        linkText: error && error.linkText,
    });

    if (notFoundAlert) {
        return notFoundAlert;
    }

    return (
        <>
            {originalStatus === ActivityStatus.Canceled ? (
                <Redirect to={`/activities/group/${activityGroup.name}`} />
            ) : mode === Mode.ACTIVITY_DETAILS ? (
                <ActivityGroupEditDetails originalStatus={originalStatus} />
            ) : (
                <ActivityGroupEditDeliveryForm />
            )}
        </>
    );
};

export default ActivityGroupEdit;
