import {
    Icon,
    HelpPanel,
    Box,
    Link,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';

type HelpPanelProps = {
    content: JSX.Element;
    links: {
        href: string;
        text: string;
    }[];
    title: string;
};

const Panel = ({ title, content, links }: HelpPanelProps) => {
    return (
        <HelpPanel
            header={
                <Box data-id="title-box" variant="h2">
                    {title}
                </Box>
            }
            footer={
                links && (
                    <Box>
                        <Box variant="h2">
                            Learn more <Icon name="external" />
                        </Box>
                        <SpaceBetween size="s">
                            {links.map(({ href, text }, i) => (
                                <Link key={i} href={href} target="_blank">
                                    {text}
                                </Link>
                            ))}
                        </SpaceBetween>
                    </Box>
                )
            }
        >
            <SpaceBetween size="l">{content}</SpaceBetween>
        </HelpPanel>
    );
};

export default Panel;
