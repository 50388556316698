import * as PanelContent from './HelpPanelContent';
import { HelpPanelConfig } from './helpPanelTypes';

export const HELP_PANELS_MAP: Readonly<Record<string, HelpPanelConfig>> =
    Object.values(PanelContent).reduce(
        (acc, panel) => ({
            ...acc,
            [panel.title]: panel,
        }),
        {},
    );
