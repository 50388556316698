/*
    TableHeader renders the title and actions passed to it from the parent component
*/
import React from 'react';

const TableHeader = ({
    actions,
    title,
    count = '',
}: {
    actions?: any;
    title: string;
    count?: string | number;
}) => {
    return (
        <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
                <h2>
                    {title}

                    {count && (
                        <span className="awsui-util-header-counter">
                            {' '}
                            ({count})
                        </span>
                    )}
                </h2>
            </div>
            {actions && (
                <div className="awsui-util-action-stripe-group">{actions}</div>
            )}
        </div>
    );
};

export default TableHeader;
