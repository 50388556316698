import { useMemo, useState } from 'react';
import ActivityGroupEditDetailsForm from './ActivityGroupEditDetailsForm';
import {
    Form,
    Button,
    Header,
    SpaceBetween,
    Modal,
    Box,
    TextContent,
    StatusIndicator,
} from '@amzn/awsui-components-react-v3';
import { useHistory } from 'react-router-dom';
import scheduleManagementApi from '../../../../../api/scheduleManagementApi';
import CancelModal, {
    CancelModalProps,
} from '../../../../../../common/components/CancelModal/CancelModal';
import { createValidations } from '../../utils/validations';
import { useActivityGroup } from '../../hooks/hooks';
import { DeliverySession } from '../../../../../interfaces/activity';
import { ActivityStatus } from '../../../Common/Common';
import FormActions from '../FormActions/FormActions';

const ActivityGroupEditDetails = ({
    originalStatus,
}: {
    originalStatus: ActivityStatus;
}) => {
    const history = useHistory();
    const activityGroup = useActivityGroup();

    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const [isOneWayModalVisible, setIsOneWayModalVisible] = useState(false);
    const [isAcknowledgeButtonSubmitting, setIsAcknowledgeButtonSubmitting] =
        useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const cancelModalProps: CancelModalProps = {
        isCancelModalVisible,
        setIsCancelModalVisible,
        onCancelConfirm: () => {
            history.push({
                pathname: activityGroup.pk
                    ? `/activities/group/${activityGroup.name}`
                    : `/activities/group/list`,
            });
        },
        testPrefix: 'EditActivityGroup',
    };

    const [saveDisabled, saveDisabledReason] = useMemo(() => {
        for (const validation of Object.keys(createValidations)) {
            if (!createValidations[validation].isValid(activityGroup)) {
                return [true, createValidations[validation].invalidMessage];
            }
        }
        return [false, ''];
    }, [activityGroup]);

    const checkForAcknowledgment = () => {
        setIsFormSubmitting(true);
        if (
            originalStatus !== activityGroup.status &&
            [
                ActivityStatus.Canceled.valueOf(),
                ActivityStatus.Active.valueOf(),
            ].includes(activityGroup.status)
        ) {
            setIsOneWayModalVisible(true);
        } else {
            handleClickSaveButton();
        }
    };

    const handleClickSaveButton = async () => {
        const activityGroupDetails = {
            name: activityGroup.name,
            active: true,
            status: activityGroup.status,
            program_name: activityGroup.program_name,
            catalog_item_id: activityGroup.catalog_item_id,
            catalog_item_versioned_id: activityGroup.catalog_item_versioned_id,
            start_timestamp: activityGroup.start_timestamp,
            end_timestamp: activityGroup.end_timestamp,
        };
        await scheduleManagementApi.putActivityGroup(activityGroupDetails);

        await Promise.all(
            activityGroup.child_activities.map(async (activity) => {
                const {
                    pk,
                    delivery_name,
                    option_number,
                    delivery_sessions,
                    ...other_activity_properties
                } = activity;

                const truncated_sessions: DeliverySession[] = [];
                if (delivery_sessions?.length > 0) {
                    for (const session of delivery_sessions) {
                        if (session.v_ilt_info) {
                            const {
                                url,
                                recording_url,
                                ...truncated_v_ilt_info
                            } = session.v_ilt_info;
                            truncated_sessions.push({
                                ...session,
                                v_ilt_info: truncated_v_ilt_info,
                            });
                        } else {
                            truncated_sessions.push(session);
                        }
                    }
                }

                const filteredActivity = {
                    ...other_activity_properties,
                    delivery_sessions: truncated_sessions,
                };

                if (pk) {
                    return scheduleManagementApi.updateActivityById({
                        id: pk,
                        activity: filteredActivity,
                    });
                }
            }),
        );

        await new Promise((resolve) => setTimeout(resolve, 500));
        history.push({
            pathname: `/activities/group/${activityGroup.name}/`,
        });
    };

    if (activityGroup.is_loading) {
        return (
            <StatusIndicator type="loading">
                Loading Activity Group...
            </StatusIndicator>
        );
    }
    return (
        <Form
            header={
                <Header variant="h1">
                    {activityGroup.pk
                        ? activityGroup.name
                        : 'Add activity group'}
                </Header>
            }
            actions={
                <FormActions
                    isSubmitting={isFormSubmitting}
                    isSaveDisabled={saveDisabled}
                    saveDisabledReason={saveDisabledReason}
                    setIsCancelModalVisible={setIsCancelModalVisible}
                    onSave={checkForAcknowledgment}
                />
            }
        >
            {
                <ActivityGroupEditDetailsForm
                    mode={activityGroup.pk ? 'EDIT' : 'CREATE'}
                />
            }
            <CancelModal {...cancelModalProps} />
            <Modal
                data-testid="activity-group-one-way-status-change"
                header="One-Way Status Change Detected"
                onDismiss={() => {
                    setIsFormSubmitting(false);
                    setIsOneWayModalVisible(false);
                }}
                visible={isOneWayModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                disabled={isAcknowledgeButtonSubmitting}
                                variant="normal"
                                onClick={() => {
                                    setIsFormSubmitting(false);
                                    setIsOneWayModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={isAcknowledgeButtonSubmitting}
                                loading={isAcknowledgeButtonSubmitting}
                                variant="primary"
                                onClick={() => {
                                    setIsAcknowledgeButtonSubmitting(true);
                                    setIsOneWayModalVisible(false);
                                    handleClickSaveButton();
                                }}
                            >
                                Acknowledge and Save
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <TextContent>
                    {`Setting the Activity Group status to ${
                        activityGroup.status === ActivityStatus.Active
                            ? 'Active'
                            : 'Canceled'
                    } is a one-way change that will result in Activities being made ${
                        activityGroup.status === ActivityStatus.Active
                            ? 'available'
                            : 'unavailable'
                    } for registration by customers.`}
                </TextContent>
            </Modal>
        </Form>
    );
};

export default ActivityGroupEditDetails;
