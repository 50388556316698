import {
    DEFAULT_ACTIVITY_STATUS,
    DEFAULT_LANGUAGE,
    DEFAULT_PROVIDER,
    DEFAULT_TIMEZONE,
    DEFAULT_WAITLIST_OPTION,
} from '../../../constants';
import { CreateActivityData } from '../../../interfaces/activity';
import ActivityCreateForm from './ActivityCreateForm';

export const createActivityFormState: Omit<
    CreateActivityData,
    'delivery_sessions'
> = {
    activity_name: '',
    activity_status: DEFAULT_ACTIVITY_STATUS,
    activity_type: '',
    program: '',
    partner_initiative: '',
    activity_audience: '',
    activity_modality: '',
    course_name: '',
    course_catalog_item_id: '',
    catalog_item_versioned_id: '',
    topic_name: '',
    operations_owner: '',
    operations_owner_email: '',
    scheduler: '',
    scheduler_email: '',
    waitlist_enabled: DEFAULT_WAITLIST_OPTION,
    customer_support_manager: '',
    customer_support_manager_email: '',
    provider: DEFAULT_PROVIDER,
    delivery_timezone: DEFAULT_TIMEZONE,
    delivery_city: '',
    delivery_state: '',
    delivery_country: '',
    delivery_region: '',
    delivery_geo: '',
    delivery_language: DEFAULT_LANGUAGE,
    course_days: 1,
    auto_assign_instructor: false,
};

const ActivityCreate = () => (
    <ActivityCreateForm createActivityFormState={createActivityFormState} />
);

export default ActivityCreate;
