/*
    AdminBusinessData.Table.tsx renders a list consisting of single values in a data table with pagination, sorting and Action Buttons
*/
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import {
    Button,
    Table,
    TableFiltering,
    TablePagination,
} from '@amzn/awsui-components-react';

import TableHeader from '../../../common/components/TableHeader/TableHeader';
import { GRIMSBY_PAGE_COUNT } from '../../../common/constants/grimsby';

export interface AdminBusinessDataTableProps<BusinessDataType> {
    columnDefinitions: Array<Table.ColumnDefinition>;
    items: BusinessDataType[];
    itemCount: number;
    itemDisplayNameSingular: string;
    itemNameKey: string;
    loading: boolean;
    loadingText: string;
    title: string;
    setCreating: Dispatch<SetStateAction<boolean>>;
    setLoadingCreateModal: Dispatch<SetStateAction<boolean>>;
    onCreateModalOpen?: () => Promise<void>;
    serverSidePaginationProps?: AdminBusinessDataTableSSPaginationProps;
}

export interface AdminBusinessDataTableSSPaginationProps {
    onPaginationChange: (
        event: CustomEvent<TablePagination.PaginationChangeDetail>,
    ) => Promise<void>;
    onFilteringDelayedInput: (
        event: CustomEvent<TableFiltering.FilteringChangeDetail>,
    ) => Promise<void>;
    pagesCount: number;
    currentPageIndex: number;
}

const BusinessDataTable = <BusinessDataType,>({
    columnDefinitions,
    items,
    itemCount,
    itemDisplayNameSingular,
    itemNameKey,
    loading,
    loadingText,
    title,
    setCreating,
    setLoadingCreateModal,
    onCreateModalOpen,
    serverSidePaginationProps,
}: PropsWithChildren<AdminBusinessDataTableProps<BusinessDataType>>) => {
    const filterText: string = `Search for ${title.toLocaleLowerCase()}`;
    const paginationProps: TablePagination.Props = {
        pageSize: GRIMSBY_PAGE_COUNT,
    };
    const filteringProps: TableFiltering.Props = {
        filteringPlaceholder: filterText,
        filteringLabel: filterText,
    };
    if (columnDefinitions.length) {
        // Only apply filtering on the first column, a.k.a. the name of business data
        filteringProps.filteringFields = [columnDefinitions[0].id as string];
    }
    if (serverSidePaginationProps) {
        paginationProps.pagesCount = serverSidePaginationProps.pagesCount;
        paginationProps.disabled = loading;
        paginationProps.currentPageIndex =
            serverSidePaginationProps.currentPageIndex;
        paginationProps.onPaginationChange =
            serverSidePaginationProps.onPaginationChange;
        filteringProps.filteringFunction = null;
        filteringProps.onFilteringDelayedInput =
            serverSidePaginationProps.onFilteringDelayedInput;
    }

    const emptyTableComponent = (
        <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No {title.toLocaleLowerCase()}</b>
            </div>
            <p className="awsui-util-mb-s">
                No {title.toLocaleLowerCase()} to display.
            </p>
        </div>
    );

    const NO_ADD_BUTTON_TYPES = new Set([
        'activity_audience',
        'instructor_status',
    ]);
    const hideAddButton = (itemKey: string): boolean => {
        return NO_ADD_BUTTON_TYPES.has(itemKey);
    };

    return (
        <Table
            data-testid={`AdminBusinessDataTable-${itemNameKey}`}
            loading={loading}
            loadingText={loadingText}
            columnDefinitions={columnDefinitions}
            items={items}
            empty={emptyTableComponent}
            header={
                <TableHeader
                    title={title}
                    actions={
                        hideAddButton(itemNameKey) ? null : (
                            <>
                                <Button
                                    icon="add-plus"
                                    variant="primary"
                                    onClick={() => {
                                        if (onCreateModalOpen !== undefined) {
                                            setLoadingCreateModal(true);
                                            setCreating(true);
                                            onCreateModalOpen();
                                            setLoadingCreateModal(false);
                                        } else {
                                            setCreating(true);
                                        }
                                    }}
                                    data-testid={`AdminBusinessData-${itemNameKey}-CreateBtn`}
                                >
                                    Add {itemDisplayNameSingular.toLowerCase()}
                                </Button>
                            </>
                        )
                    }
                    count={itemCount ? `${itemCount}` : ''}
                />
            }
        >
            <TableFiltering {...filteringProps} />
            <TablePagination {...paginationProps} />
        </Table>
    );
};

export default BusinessDataTable;
