import React from 'react';

import InstructorForm from './InstructorForm';
import { InstructorProfileData } from '../../../interfaces/instructorProfile';

export const createInstructorState: Partial<InstructorProfileData> = {
    first_name: '',
    last_name: '',
    email: '',
    geo: '',
    country: '',
    instructor_region: '',
    programs: [],
    address_line_1: '',
    address_line_2: '',
    postal_code: '',
    city: '',
    state_province: '',
    prefer_travel: undefined,
    preferred_airport_code: '',
    final_approval_date: undefined,
    instructor_status: '',
    instructor_type: '',
    onboarding_date: undefined,
    phone_number: undefined,
    amazon_alias: '',
    amazon_job_role: '',
    amazon_manager_name: '',
    amazon_manager_email: '',
    aws_lms_email: '',
    sponsoring_company_name: '',
    external_poc: '',
    signed_nda: undefined,
    authorized_instructor_agreement_status: '',
    aws_authorized_candidate_fundamentals: undefined,
    is_freelancer: undefined,
    freelance_agency: '',
    freelancer_status: '',
    freelance_onboarding_date: undefined,
    freelance_poc_email: '',
    is_alumni: false,
};

const CreateInstructor = () => {
    return (
        <InstructorForm
            data-testid="CreateInstructor"
            initialFormState={createInstructorState}
        />
    );
};

export default CreateInstructor;
